import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Text, Stack, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Select, Spinner, Divider } from "@chakra-ui/react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import Swal from "sweetalert2";
import { API_URL } from "variables/constant";
import { getParam } from "util/common";
import QuestionRenderer from "./QuestionRenderer"; // Import the new component

const QuestionSetImportModal = ({ initialIndex, isOpen, onClose }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(initialIndex || 0);
  const [subjects, setSubjects] = useState([]);
  const [books, setBooks] = useState([]);
  const [units, setUnits] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    loadSubjects();
  }, []);

  const loadSubjects = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/otds/list_lerningarea`);
      if (response.data && response.data.status === "success") {
        setSubjects(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
      alert("Cannot connect to the server!");
    }
  };

  const loadBooks = async (className, subjectName) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/otds/list_lesson`, { class_name: className, subject_name: subjectName });
      if (response.data && response.data.status === "success") {
        setBooks(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching books:", error);
      alert("Cannot connect to the server!");
    }
  };

  const loadUnits = (lessons) => {
    setUnits(lessons);
  };

  const loadExams = async (unitIds) => {
    try {
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/otds/list_otds_exam`, {
        class_name: selectedClass,
        subject_name: selectedSubject,
        bookid: selectedBook,
        unit_ids: JSON.stringify(unitIds)
      }, { withCredentials: true });
      if (response.data && response.data.status === "success") {
        //alert((response.data.collected_data).length)
        setExams(response.data.collected_data);
      }
      else{
        setExams([])
      }
      setLoading(false)
    } 
    catch (error) {
      console.error("Error fetching exams:", error);
      Swal.fire("Warning", "ไม่สามารถดึงข้อมูลจาก server ได้กรุณาลองตัวเลือกอื่น", "warning");
      setSelectedUnit("")
      setLoading(false)
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < exams.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSelectChange = (e, type) => {
    const value = e.target.value;
    if (type === 'class') {
      setSelectedClass(value);
      setSelectedSubject(""); // reset subject selection
      setSelectedBook(""); // reset book selection
      setBooks([]); // reset books
      setUnits([]); // reset units
      setExams([]); // reset exams
    } else if (type === 'subject') {
      setSelectedSubject(value);
      setSelectedBook(""); // reset book selection
      setUnits([]); // reset units
      setExams([]); // reset exams
      if (selectedClass && value) {
        loadBooks(selectedClass, value);
      }
    } else if (type === 'book') {
      setSelectedBook(value);
      const selectedBookObj = books.find(book => `${book.id}` === `${value}`);
      if (selectedBookObj) {
        loadUnits(selectedBookObj.lessons);
      }
    } else if (type === 'unit') {
      setSelectedUnit(value);
      loadExams(value === "all" ? units.map(unit => unit.id) : [value]);
    }
  };

  const extractImageUrls = (text) => {
    const urlRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg))/gi;
    return text.match(urlRegex) || [];
  };

  const extractImageUrlsFromArray = (textArray) => {
    const allImageUrls = textArray.flatMap(text => extractImageUrls(text));
    return allImageUrls;
  };

  const getQuestionType = (type) => {
    if(type == "MC"){
      return "ตัวเลือก"
    }
    else if(type == "SA"){
      return "เติมคำ"
    }
    else if(type == "MA"){
      return "จับคู่"
    }
  }

  const uploadImportedQuestions = async (upload_questions) => {
    var question_id = "";
    const exam_id = getParam("eid");

    if (!exam_id) {
      Swal.fire("Warning", "Wrong input link", "warning");
      return;
    }

    if (upload_questions.length > 0) {
      var question = upload_questions[0];
      upload_questions = upload_questions.slice(1);
      var q = question["ItemQuestion"];

      var operation = "add";
      var question_type = question.QuestionType;
      var sub_question_type = question.SubQuestionType;
      var item_no = question.ItemNo
      var content = '';
      var source = 'import';
      var otim_item_id = question["ItemID"];
      var test_set_id = question["TestSetID"];
      var ansArray = [];
      var correctArray = [];
      var imgArray = [];
      var sequenceArray = [];
      var matching_items = {};
      var images = [];
      var explanation = question["Explanation"];
      var modifiedDate = question["LastModifiedDate"] ?? ""

      if (q) {
        images = extractImageUrls(q);
        content = q.replace("<img ", "<img crossorigin=\"anonymous\" ");
        content = content.replace(/http.+images/, "/images");
      }

      if (question_type === "MC") {
        question_type = "choice";
      } else if (question_type === "SA") {
        question_type = "fill";
      } else if (question_type === "MA") {
        question_type = "matching";
      }

      var cg = question["ItemChoice_ResponseItemGroup"];
      if (cg !== undefined) {
        var choices = cg["ItemChoice"];
        var i = 0;

        while (i < choices.length) {
          var answer = choices[i]["ItemChoiceKey"];
          ansArray.push(choices[i]["ItemChoice"]);
          imgArray.push('');
          sequenceArray.push(choices[i]["ItemChoiceSeq"]);

          if (answer === "True") {
            correctArray.push(1);
          } else {
            correctArray.push(0);
          }
          i += 1;
        }
      }

      if (question_type === "fill") {
        var cg = question["ItemShortAnswer_ResponseItemGroup"];

        if (cg !== undefined) {
          var shortAnswers = cg["ItemShortAnswer"];
          var i = 0;

          while (i < shortAnswers.length) {
            ansArray.push(shortAnswers[i]["ItemAnswer"]);
            correctArray.push(1);
            imgArray.push('');
            sequenceArray.push(1);

            i += 1;
          }
        }
      }

      if (question_type === "matching") {
        var iq = question["ItemMatchingQuestion_ResponseItemGroup"];
        var ic = question["ItemMatchingChoice_ResponseItemGroup"];

        matching_items = { ItemMatchingQuestion: iq.ItemMatchingQuestion, ItemMatchingChoice: ic.ItemMatchingChoice };
      }

      if (question_type === "choice" || question_type === "fill" || question_type === "matching") {
        const ansImgs = extractImageUrlsFromArray(ansArray);
        const questionAndAnswerImgs = [...images, ...ansImgs];

        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/question_insert`, {
            operation,
            question_id,
            question_type,
            sub_question_type,
            item_no,
            exam_id,
            content,
            otim_item_id,
            test_set_id,
            imgArray: JSON.stringify(imgArray),
            ansArray: JSON.stringify(ansArray),
            correctArray: JSON.stringify(correctArray),
            sequenceArray: JSON.stringify(sequenceArray),
            matching_items: JSON.stringify(matching_items),
            explanation,
            modified_date: modifiedDate,
            source
          });

          if (response.data && response.data.status === "success") {
            if (upload_questions.length > 0) {
              uploadImportedQuestions(upload_questions);
            } else {
              Swal.fire({
                text: "ระบบได้ทำการบันทึกเรียบร้อย.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "ตกลง",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              }).then(result => {
                if (result.isConfirmed) {
                  onClose(true); // Close the modal on success
                }
              });
            }
          } else {
            Swal.fire("Warning", "ไม่สามารถบันทึกข้อมูลได้", "warning");
          }
        } catch (error) {
          //console.log(error);
          Swal.fire("Warning", "ไม่สามารถบันทึกข้อมูลได้", "warning");
        }
      }
      else{
        if(upload_questions.length > 0){
          uploadImportedQuestions(upload_questions);
        }
        else{
          Swal.fire({
            text: "ระบบได้ทำการบันทึกเรียบร้อย",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          }).then(result => {
            if (result.isConfirmed) {
              onClose(true); // Close the modal on success
            }
          });
        }
      }
    }
  };

  const handleReset = () => {
    setSelectedClass("");
    setSelectedSubject("");
    setSelectedBook("");
    setSelectedUnit("");
    setBooks([]);
    setUnits([]);
    setExams([]);
  };

  return (
    <Modal closeOnOverlayClick={false} size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>นำเข้าคำถาม</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Box flex={1} p={4}>
              <Text fontSize="xl" mb={4}>เลือกรายการ</Text>
              <Select isDisabled={loading} placeholder="เลือกระดับชั้น" mb={4} onChange={(e) => handleSelectChange(e, 'class')} value={selectedClass}>
                <option value="ป.1">ป.1</option>
                <option value="ป.2">ป.2</option>
                <option value="ป.3">ป.3</option>
                <option value="ป.4">ป.4</option>
                <option value="ป.5">ป.5</option>
                <option value="ป.6">ป.6</option>
                <option value="ม.1">ม.1</option>
                <option value="ม.2">ม.2</option>
                <option value="ม.3">ม.3</option>
                <option value="ม.4">ม.4</option>
                <option value="ม.5">ม.5</option>
                <option value="ม.6">ม.6</option>
              </Select>
              <Select isDisabled={loading} placeholder="เลือกวิชา" mb={4} onChange={(e) => handleSelectChange(e, 'subject')} value={selectedSubject} disabled={!selectedClass}>
                {subjects.map(subject => (
                  <option key={subject.name} value={subject.name}>{subject.name}</option>
                ))}
              </Select>
              <Select placeholder="เลือกหนังสือเรียน" mb={4} onChange={(e) => handleSelectChange(e, 'book')} value={selectedBook} disabled={!selectedSubject}>
                {books.map(book => (
                  <option key={`book_${book.id}`} value={book.id}>{book.name}</option>
                ))}
              </Select>
              <Select isDisabled={loading} placeholder="เลือกหน่วย/บท" mb={4} onChange={(e) => handleSelectChange(e, 'unit')} value={selectedUnit} disabled={!selectedBook}>
                {/*<option value="all">--- ทั้งหมด ---</option>*/}
                {units.map(unit => (
                  <option key={`unit_${unit.id}`} value={unit.id}>{unit.name}</option>
                ))}
              </Select>
              <Flex w="100%" justify={"end"}>
                <Button variant="ghost" colorScheme="blue" onClick={handleReset}>ล้างค่า</Button>
              </Flex>
            </Box>
            <Box flex={3} p={4}>
              {exams.length > 0 ? (
                <>
                  <Flex justify={"end"}>
                    <Text color={"blue.600"} fontWeight={600} mb={2}>ประเภทคำถาม: {getQuestionType(exams[currentQuestionIndex].QuestionType)}</Text>
                  </Flex>
                  <Divider />
                  {exams.length > 0 && <Box mt={1} mb={3}>
                    <Flex bgColor={"blue.50"} mb={1} align="center" justify="space-between">
                      <Button leftIcon={<BiLeftArrow />} variant="ghost" colorScheme="blue" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>ก่อนหน้า</Button>
                      <Text color="navy">{`ข้อที่ ${currentQuestionIndex + 1}/${exams.length}`}</Text>
                      <Button rightIcon={<BiRightArrow />} variant="ghost" colorScheme="blue" onClick={handleNextQuestion} disabled={currentQuestionIndex === exams.length - 1}>ถัดไป</Button>
                    </Flex>
                  </Box>}
                  <QuestionRenderer question={exams[currentQuestionIndex]} />
                </>
              ) : (
                <Flex align={"center"} justify={"center"} borderColor={"blackAlpha.200"} borderWidth={"1px"} w={"100%"} h={"100%"}>
                  {loading ? <Spinner /> : <Text>ไม่มีชุดข้อสอบ</Text>}
                </Flex>
              )}
              
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button isDisabled={exams.length === 0 || saving} ml={2} mt={4} colorScheme="blue" onClick={async () => {
            setSaving(true); // Start loading
            const upload_questions = [...exams];
            await uploadImportedQuestions(upload_questions); // Stop loading when done
            setSaving(false)
          }}>
            {saving ? <Spinner size="sm" /> : 'นำเข้า'}
          </Button>
          <Button ml={2} mt={4} variant="outline" colorScheme="gray" onClick={onClose}>ยกเลิก</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuestionSetImportModal;



/*import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Text, Stack, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Select } from "@chakra-ui/react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { API_URL } from "variables/constant";

const QuestionSetImportModal = ({ initialIndex, isOpen, onClose }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(initialIndex || 0);
  const [subjects, setSubjects] = useState([]);
  const [books, setBooks] = useState([]);
  const [units, setUnits] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");

  useEffect(() => {
    loadSubjects();
  }, []);

  const loadSubjects = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/otds/list_lerningarea`);
      if (response.data && response.data.status === "success") {
        setSubjects(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching subjects:", error);
      alert("Cannot connect to the server!");
    }
  };

  const loadBooks = async (className, subjectName) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/otds/list_lesson`, { class_name: className, subject_name: subjectName });
      if (response.data && response.data.status === "success") {
        setBooks(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching books:", error);
      alert("Cannot connect to the server!");
    }
  };

  const loadUnits = (lessons) => {
    setUnits(lessons);
  };

  const loadExams = async (unitIds) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/otds/list_otds_exam`, {
        class_name: selectedClass,
        subject_name: selectedSubject,
        bookid: selectedBook,
        unit_ids: JSON.stringify(unitIds)
      }, { withCredentials: true });
      if (response.data && response.data.status === "success") {
        setExams(response.data.collected_data);
      }
    } catch (error) {
      console.error("Error fetching exams:", error);
      console.log(error)
      alert("Cannot connect to the server!");
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < exams.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSelectChange = (e, type) => {
    const value = e.target.value;
    if (type === 'class') {
      setSelectedClass(value);
      setSelectedSubject(""); // reset subject selection
      setSelectedBook(""); // reset book selection
      setBooks([]); // reset books
      setUnits([]); // reset units
      setExams([]); // reset exams
    } else if (type === 'subject') {
      setSelectedSubject(value);
      setSelectedBook(""); // reset book selection
      setUnits([]); // reset units
      setExams([]); // reset exams
      if (selectedClass && value) {
        loadBooks(selectedClass, value);
      }
    } else if (type === 'book') {
      setSelectedBook(value);
      const selectedBookObj = books.find(book => `${book.id}` === `${value}`);
      if (selectedBookObj) {
        loadUnits(selectedBookObj.lessons);
      }
    } else if (type === 'unit') {
      setSelectedUnit(value);
      loadExams(value === "all" ? units.map(unit => unit.id) : [value]);
    }
  };

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>นำเข้าคำถาม</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Box flex={1} p={4}>
              <Text fontSize="xl" mb={4}>เลือกรายการ</Text>
              <Select placeholder="เลือกระดับชั้น" mb={4} onChange={(e) => handleSelectChange(e, 'class')}>
                <option value="ป.1">ป.1</option>
                <option value="ป.2">ป.2</option>
                <option value="ป.3">ป.3</option>
                <option value="ป.4">ป.4</option>
                <option value="ป.5">ป.5</option>
                <option value="ป.6">ป.6</option>
                <option value="ม.1">ม.1</option>
                <option value="ม.2">ม.2</option>
                <option value="ม.3">ม.3</option>
                <option value="ม.4">ม.4</option>
                <option value="ม.5">ม.5</option>
                <option value="ม.6">ม.6</option>
              </Select>
              <Select placeholder="เลือกวิชา" mb={4} onChange={(e) => handleSelectChange(e, 'subject')} value={selectedSubject} disabled={!selectedClass}>
                {subjects.map(subject => (
                  <option key={subject.name} value={subject.name}>{subject.name}</option>
                ))}
              </Select>
              <Select placeholder="เลือกหนังสือเรียน" mb={4} onChange={(e) => handleSelectChange(e, 'book')} value={selectedBook} disabled={!selectedSubject}>
                {books.map(book => (
                  <option key={`book_${book.id}`} value={book.id}>{book.name}</option>
                ))}
              </Select>
              <Select placeholder="เลือกหน่วย/บท" mb={4} onChange={(e) => handleSelectChange(e, 'unit')} disabled={!selectedBook}>
                <option value="all">--- ทั้งหมด ---</option>
                {units.map(unit => (
                  <option key={`unit_${unit.id}`} value={unit.id}>{unit.name}</option>
                ))}
              </Select>
            </Box>
            <Box flex={3} p={4}>
              {exams.length > 0 ? (
                <Box borderWidth={1} p={6} rounded="md" bg="white">
                  <Text mb={4} fontWeight={600}>{exams[currentQuestionIndex].ItemQuestion}</Text>
                  <Stack spacing={4}>
                    {exams[currentQuestionIndex].ItemChoice_ResponseItemGroup?.ItemChoice.map((choice, index) => (
                      <Box key={index} borderWidth={1} p={4} rounded="md" bg="white">
                        {choice.ItemChoice}
                      </Box>
                    ))}
                  </Stack>
                </Box>
              ) : (
                <Text>ไม่มีชุดข้อสอบ</Text>
              )}
              <Box mt={2}>
                <Flex align="center" justify="space-between">
                  <Button leftIcon={<BiLeftArrow />} variant="ghost" colorScheme="blue" onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>ก่อนหน้า</Button>
                  <Text color="navy">{`ข้อที่ ${currentQuestionIndex + 1}/${exams.length}`}</Text>
                  <Button rightIcon={<BiRightArrow />} variant="ghost" colorScheme="blue" onClick={handleNextQuestion} disabled={currentQuestionIndex === exams.length - 1}>ถัดไป</Button>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button mt={4} variant="outline" colorScheme="blue" onClick={onClose}>ยกเลิก</Button>
          <Button ml={2} mt={4} colorScheme="blue" onClick={onClose}>นำเข้า</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuestionSetImportModal;
*/