import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Input,
  Stack,
  Text,
  useToast,
  Box,
  Flex,
  Image,
  Spinner,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { getParam } from 'util/common';
import { showWarningToast } from 'util/alertUtil';

const LessonModal = ({ isOpen, onClose, mode = 'insert', defaultLessonName = '', defaultLevel = '', defaultImage = '', defaultLessonID = '', defaultSubjectModelID = '', handleLessonSaved }) => {
  const [lessonName, setLessonName] = useState('');
  const [level, setLevel] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadNewFile, setIsUploadNewFile] = useState(false);
  const [classes, setClasses] = useState([]);
  const [subjectModelID, setSubjectModelID] = useState()
  const toast = useToast();
  const [classroomID, setClassroomID] = useState()
  const [imgFile, setImgFile] = useState()

  useEffect(() => {
    if(!defaultSubjectModelID){
       console.log(`defaultSubjectModelID -> ${defaultSubjectModelID}`)
    }
    setSubjectModelID(defaultSubjectModelID)
    const classID = getParam('classroom_id')
    setClassroomID(classID)

  },[isOpen])

  useEffect(() => {
    if (mode === 'edit') {
      setLessonName(defaultLessonName);
      setLevel(defaultLevel);
      setFiles(defaultImage ? [{ preview: defaultImage }] : []);
      setIsUploadNewFile(false)
    } else {
      setFiles([]);
    }
  }, [mode, defaultLessonName, defaultLevel, defaultImage]);

  useEffect(() => {
    const loadClasses = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/subjects/list_all_classes`);
        if (response.data && response.data.status === 'success') {
          setClasses(response.data.classes);
        } else {
          toast({
            title: 'Error',
            description: 'Cannot load classes',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Cannot connect to the server!',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    loadClasses();
  }, [toast]);

  const onDrop = (acceptedFiles) => {
    setIsUploadNewFile(true)
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  const uploadNewLesson = async (file) => {
    if(isUploadNewFile){
        const formData = new FormData();
        formData.append('file', file);

        setIsLoading(true);

        try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data && response.data.image_file) {
            const image_file = response.data.image_file;
            saveLessonInfo(image_file);
        } else {
            setIsLoading(false);
            toast({
            title: 'Warning',
            description: 'กรุณาเลือกภาพหน้าปก',
            status: 'warning',
            duration: 3000,
            isClosable: true,
            });
        }
        } catch (error) {
            setIsLoading(false);
            toast({
                title: 'Warning',
                description: 'กรุณาเลือกภาพหน้าปก',
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
        }
    }
    else if(defaultImage){
        saveLessonInfo(defaultImage)
    }
  };

  const saveLessonInfo = async (lessonImg) => {
    if(!subjectModelID){
        alert(`ipst_subject_model_id: ${subjectModelID}`)
        setIsLoading(false);
        return;
    }

    const getImageNameFromUrl = (urlOrName) => {
        // Check if the input contains slashes
        if (urlOrName.includes('/')) {
          // Split the URL by '/' and return the last part
          const parts = urlOrName.split('/');
          return parts[parts.length - 1];
        } else {
          // If there are no slashes, return the input as it is
          return urlOrName;
        }
      }

    const lessonData = {
      lesson_id: defaultLessonID ,
      lesson_opt: mode,
      ipst_subject_model_id: subjectModelID,
      ipst_class_id: level,
      lesson_name: lessonName,
      lesson_img: getImageNameFromUrl(lessonImg),
      ipst_classroom_id: classroomID,
    };

    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/model_subject/new_lesson_insert`, lessonData, { withCredentials: true });

      if (response.data && response.data.status === 'success') {
        setIsLoading(false);
        handleLessonSaved()
        toast({
          title: 'Success',
          description: 'บันทึกข้อมูลเรียบร้อยแล้ว',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
        // Reload or update your data here if necessary
      } else {
        setIsLoading(false);
        toast({
          title: 'Error',
          description: 'Cannot save lesson information',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error',
        description: 'Cannot connect to the server!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSave = () => {
    if (!lessonName || !level || files.length === 0) {
      toast({
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    uploadNewLesson(files[0]);
  };

  return (
    <Modal size='2xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{mode === 'edit' ? 'แก้ไขบทเรียน' : 'เพิ่มบทเรียนใหม่'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Box cursor={"pointer"} flex="1" {...getRootProps()} border="2px dashed" borderColor="gray.200" p={4} textAlign="center">
              <input {...getInputProps()} type="file" accept="image/*" />
              {files.length === 0 ? (
                <Text>ลากและวางไฟล์ที่นี่ หรือคลิกเพื่อเลือกไฟล์</Text>
              ) : (
                <Image src={files[0].preview} alt="Preview" />
              )}
            </Box>
            <Box flex="3" ml={4}>
              <Stack spacing={4}>
                <Input 
                  placeholder="ชื่อบทเรียน"
                  value={lessonName}
                  onChange={(e) => setLessonName(e.target.value)}
                />
                <Select 
                  placeholder="เลือกระดับชั้น"
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                >
                  {classes.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name_thai}
                    </option>
                  ))}
                </Select>
              </Stack>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave} isLoading={isLoading}>
            บันทึก
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={isLoading}>ยกเลิก</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LessonModal;
