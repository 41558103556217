import React, { useState, useEffect } from 'react';
import { Button, Flex } from '@chakra-ui/react';

const ViewToggleButton = ({ defaultColor, index, activityIndex=undefined, handleToggleIndex=undefined}) => {
  const [isAdminView, setIsAdminView] = useState(true);

  const handleAdminView = (isAdminViewOn) => {
    setIsAdminView(isAdminViewOn);

    if(handleToggleIndex){
        handleToggleIndex(index, isAdminViewOn)
    }
  };

  return (
    <Flex key={(index && activityIndex)?`${index}${activityIndex}`:`${index}`} maxW={"120px"} border="1px solid" borderColor="gray.300" borderRadius="md" overflow="hidden">
      <Button
        onClick={()=>{handleAdminView(true)}}
        variant="ghost"
        flex="1"
        size='xs'
        px={2}
        borderRadius="none"
        borderRight="1px solid"
        borderColor="gray.300"
        fontWeight={isAdminView ? '500' : "200"}
        bgColor={isAdminView ? 'blue.400' : 'none'}
        color={isAdminView ? 'white' : defaultColor}
      >
        มุมมองครู
      </Button>
      <Button
        onClick={()=>{handleAdminView(false)}}
        variant="ghost"
        flex="1"
        px={2}
        borderRadius="none"
        size='xs'
        fontWeight={!isAdminView ? '500' : "200"}
        bgColor={!isAdminView ? 'blue.400' : 'none'}
        color={!isAdminView ? 'white' : defaultColor}
      >
        นักเรียน
      </Button>
    </Flex>
  );
};

export default ViewToggleButton;
