import React, { useEffect, useReducer, useState } from 'react';

import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Spacer,
  RadioGroup,
  Radio,
  Td,
  Spinner,
  Select,
} from '@chakra-ui/react';
// Assets
import { AiFillLike, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { FaCommentDots, FaCube, FaPenFancy, FaPlay, FaPlayCircle, FaPlus, FaRegPlayCircle } from 'react-icons/fa';
import { IoIosStar, IoMdShareAlt, IoMdStarHalf } from 'react-icons/io';
import { IoDocumentsSharp, IoEllipsisVerticalSharp } from 'react-icons/io5';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { InvisionLogo, SlackLogo } from 'components/Icons/Icons';

import ScienceSVG from 'assets/svg/science.svg';
import YoutubeSVG from 'assets/svg/youtube.svg';
import ConfigureSVG from 'assets/svg/configure2.svg';
import { HSeparator } from 'components/Separator/Separator.js';
import { useHistory } from 'react-router-dom';
import { bookListData } from 'variables/general';
import { youtubeData } from 'variables/general';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import GradeLevelSelectionCard from './widgets/GradeLevelSelectionCard';
import SubjectSelectionCard from './widgets/SubjectSelectionCard';
import { loadAllYoutubes } from 'apis/youtubeAPI';
import { EDUCATION_LEVEL_OPTIONS } from 'variables/constant';
import { secondsToMMSS } from 'util/youtubeUtil';
import Swal from 'sweetalert2';
import { confirmDeleteDialog } from 'util/alertUtil';
import { showSuccessDialog } from 'util/alertUtil';
import { showWarningDialog } from 'util/alertUtil';
import { deleteYoutube } from 'apis/youtubeAPI';
import ReactPaginate from 'react-paginate';
import DateRangeModal from './modals/DateRangeModal';
import { exportCSV } from 'util/common';
import moment from 'moment';

function YoutubeList() {
  const history = useHistory();
  const [displayType, setDisplayType] = useState(localStorage.getItem('displayType') || 'cards');
  const [statusList, setStatusList] = useState('all')
  const [searchText, setSearchText] = useState()
  const [selectedGradeLevels, setSelectedGradeLevels] = useState()
  const [selectedSubjectIDs, setSelectedSubjectIDs] = useState()
  const [checkedItems, setCheckedItems] = useState([]);
  const [youtubeList, setYoutubeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalItemCount, setTotalItemCount] = useState(0)

  const { isOpen: isDateRangeModalOpen, onOpen: openDateRangeModal, onClose: closeDateRangeModal } = useDisclosure();
  const [downloading, setDownloading] = useState(false)

  useEffect(()=>{
    loadYoutubeList()
  },[currentPage, pageSize, searchText])

  useEffect(()=>{
    console.log(statusList)
    console.log(selectedGradeLevels)
    console.log(selectedSubjectIDs)
    loadYoutubeList()
  },[statusList, selectedGradeLevels, selectedSubjectIDs])

  async function loadYoutubeList(){
    setIsLoading(true)
    const res = await loadAllYoutubes(selectedSubjectIDs, selectedGradeLevels, statusList, searchText, (currentPage+1), pageSize)
    if(res.status == "success"){
      setYoutubeList(res.data)
      setPageCount(Math.ceil(res.total / pageSize)) 
      setTotalItemCount(res.total);
      //setPageCount(res.total / pageSize)
      //console.log(JSON.stringify(res.data))
    }
    setIsLoading(false)
  }


  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleSearch = () =>{
    loadYoutubeList()
  }

  const handleChange = (checkedValues) => {
    setCheckedItems(checkedValues);
  };

  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white');

  const goToYoutubeExercisePage = (vid) => {
    if(vid){
      history.push(`/user/youtube-exercise/?vid=${vid}`);
    }
    else{
      history.push(`/user/youtube-exercise`);
    }
  };

  const getEducationLevelLabel = (value) => {
    const option = EDUCATION_LEVEL_OPTIONS.find(option => option.value === value);
    return option ? option.label : 'Unknown';
};

const handleSelectDates = async ({ startDate, endDate }) => {
  await exportCSV('export_test_scores', moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), setDownloading, { exportType: "youtube" })
  closeDateRangeModal();
  console.log("Selected dates:", startDate, endDate);
};

const handleDelete = async (id) => {
  const onDeleteConfirmed = async () => {
    try {
      // Call the API to delete the item
      const response = await deleteYoutube(id);
      
      // Check if deletion was successful
      if (response.data.status == "success") {
        showSuccessDialog('ลบสำเร็จ!', 'รายการถูกลบเรียบร้อยแล้ว',);
        loadYoutubeList();
        
      } else {
        // Show error message if deletion failed
        showWarningDialog('ลบไม่สำเร็จ!','เกิดข้อผิดพลาดในการลบรายการ');
      }
    } catch (error) {
      showWarningDialog('ลบไม่สำเร็จ!','เกิดข้อผิดพลาดในการลบรายการ');
    }
  };

  // Show confirmation dialog
  confirmDeleteDialog(
    'คุณแน่ใจหรือไม่?',
    'คุณต้องการที่จะลบรายการนี้หรือไม่?',
    onDeleteConfirmed
  );
}

const handleDisplayTypeChange = (type) => {
  setDisplayType(type);
  localStorage.setItem('displayType', type);
};

  const renderCardView = (youtubeList) => {
    return youtubeList.length !== 0 ? <Grid
      templateColumns={{
        sm: '1fr',
        md: 'repeat(2, auto)',
        lg: 'repeat(2, auto)',
      }}
      templateRows={{ md: 'repeat(3, auto)', lg: 'repeat(2, auto)' }}
      gap="10px"
    >

      {youtubeList.map((e, index) => {
        return <Card minH="100%">
          <CardHeader mb="2px">
            <Flex position={'relative'} justify="space-between" w="100%" cursor="pointer" _hover={{ cursor: 'pointer' }} onClick={() => goToYoutubeExercisePage(e.id)}>
              <Flex justify="center" align="center" position="relative" alignSelf="flex-start">
                <Image src={e.thumbnail_url} alt="Image" borderRadius={10} />
                <Box color="white" position="absolute" mb={2} bottom={{ base: "1", lg: "1", md: "0", sm: "1" }} left="4">
                  <Text fontSize="xs">
                    {getEducationLevelLabel(e.class)}
                  </Text>
                </Box>
                <Box color="white" position="absolute" mb={2} bottom={{ base: "1", lg: "1", md: "0", sm: "1" }} right="4">
                  <Text fontSize="xs">
                    {secondsToMMSS(e.duration)}
                  </Text>
                </Box>
                <Box color="white" position="absolute" mt={2} top={{ base: "0", lg: "1", md: "1", sm: "1" }} left="4">
                  <Text fontSize="xs">
                    {e.subjectThai}
                  </Text>
                </Box>
                <Box color="white" position="absolute" mt={1} top={{ base: "0", lg: "1", md: "1", sm: "1" }} right="4">
                  <Badge variant='solid' colorScheme={e.status == "draft" ? "orange" : "teal"}>
                    {e.status == "draft" ? "ร่าง" : "เผยแพร่แล้ว"}
                  </Badge>
                </Box>
              </Flex>
                            <Box
                w="45px"
                h="45px"
                position={'absolute'}
                left={'40%'}
                top={'40%'}
              >
                <Icon borderColor="red" as={FaRegPlayCircle} color="#fff" w="50px" h="50px" />
              </Box>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex justify="space-between" w="100%" mt={1}>
              <Text color="blue.600" fontSize="sm">
                {e.title}
              </Text>
              <Menu>
                <MenuButton alignSelf="flex-start">
                  <Icon
                    as={IoEllipsisVerticalSharp}
                    color="gray.400"
                    w="20px"
                    h="20px"
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={()=>{handleDelete(e.id)}}
                  >
                    ลบ
                  </MenuItem>
                  <MenuItem
                    onClick={()=>{goToYoutubeExercisePage(e.id)}}
                    icon={<EditIcon />}
                  >
                    แก้ไข/เข้าดู
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </CardBody>
        </Card>
      })}
    </Grid>:<Flex mt={5} justify="center" w="100%"><Text>ไม่มีข้อมูล</Text></Flex>
  }

  const renderTableView = () => {
    return (
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ภาพปก</Th>
            <Th>ชื่อวีดิทัศน์</Th>
            <Th>ชั้น</Th>
            <Th>ความยาว</Th>
            <Th>สถานะ</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {youtubeList.length !== 0 ? youtubeList.map((e, index) => (
            <Tr key={index}>
              <Td>
                <Image src={e.thumbnail_url} alt="Thumbnail" w="70px" h="50px" />
              </Td>
              <Td>{e.title}</Td>
              <Td>{getEducationLevelLabel(e.class)}</Td>
              <Td>{secondsToMMSS(e.duration)}</Td>
              <Td color={e.status === 'draft' ? 'orange.500' : 'teal'}>{e.status === 'draft' ? 'ร่าง' : 'เผยแพร่'}</Td>
              <Td minW={"130px"}>
                <Button varient="outline" size="sm" onClick={()=>{handleDelete(e.id)}} mr={1}>ลบ</Button>
                <Button variant="outline" size="sm" onClick={() => goToYoutubeExercisePage(e.id)}>
                  ดู
                </Button>
              </Td>
            </Tr>
          )):
          <Tr><Td colSpan={6} textAlign="center">ไม่มีข้อมูล</Td></Tr>
          }
        </Tbody>
      </Table>
    );
  };


  return (
    <Flex direction="column" mt={{ sm: '45px', md: '40px' }} overflowX="hidden">
      <Grid
        templateColumns={{
          sm: '1fr',
          md: 'repeat(2, auto)',
          lg: 'repeat(3, auto)',
        }}
        templateRows={{ md: 'repeat(3, auto)', lg: 'repeat(2, auto)' }}
        gap="10px"
        mt="24px"
      >
        <Card alignSelf={{ lg: 'flex-start', '2xl': 'stretch' }}>
          <CardHeader w="100%">
            <Flex justify="space-between" align="center">
              <Flex>
                <Box>
                  <Avatar src={YoutubeSVG} p={1} w="40px" h="40px" borderRadius="12px" me="15px" />
                </Box>
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    รายการวีดิทัศน์จาก Youtube
                  </Text>
                  <Text fontSize="sm" color="gray.500" fontWeight="normal">
                    {totalItemCount} วีดิทัศน์
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex justify="space-between" align="center" mb="4">
                  <Button
                    fontWeight={400}
                    variant="outline"
                    _focus={{ outline: 'none' }}
                    borderRadius={0}
                    colorScheme={displayType === 'cards' ? 'blue' : 'gray'}
                    onClick={() => handleDisplayTypeChange('cards')}
                  >
                    Cards
                  </Button>
                  <Button
                    fontWeight={400}
                    variant="outline"
                    _focus={{ outline: 'none' }}
                    borderRadius={0}
                    colorScheme={displayType === 'table' ? 'blue' : 'gray'}
                    onClick={() => handleDisplayTypeChange('table')}
                  >
                    Table
                  </Button>
                </Flex>

                <Flex ml={2}>
                <Input
                  mr={2}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="ค้นหาวิดีทัศน์..."
                  border="1px solid"
                  borderColor={'gray.200'}
                  _hover={{}}
                  _focus={{}}
                  color="gray.500"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />

                </Flex>

                <Button variant="primary" px={2} onClick={() => goToYoutubeExercisePage()}>
                  <Flex align="center" color="#fff" justifyContent="center">
                    <Icon as={AiOutlinePlus} w="18px" h="18px" fontWeight="bold" me="4px" />
                    <Text fontSize="14px" mt="4px">
                      เพิ่มวิดีทัศน์
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <HSeparator my="16px" />
          { !isLoading && (displayType == "cards" ? renderCardView(youtubeList) : renderTableView())}
          { isLoading  && <Flex w={"100%"} mt={5} justify={"center"}><Spinner mr={1} /><Text>Loading...</Text></Flex>}

          <Flex justify={"space-between"} align={"center"} mr={3} mt={5}>
            <Box>
                <Text pb={2} mr={2} display="inline-block">แสดง</Text>
                <Select
                  value={pageSize}
                  onChange={(e) => {
                    setCurrentPage(0)
                    setPageSize(Number(e.target.value))
                  }}
                  width="auto"
                  display="inline-block"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                </Select>
                <Text pb={2} ml={2} display="inline-block">รายการต่อหน้า</Text>
            </Box>
            <Flex align={"center"}>
              {pageCount > 0 && <Text pb={2} mr={2}>หน้า</Text>}
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={pageCount}
                forcePage={currentPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </Flex>
          </Flex>

        </Card>
        <Stack direction="column" spacing="24px">

          <Flex borderRadius={20} bgColor="white" mt={5} justify={"center"} align={'center'} p={3}>
            <Button colorScheme='blue' fontWeight={400} variant='outline' onClick={() => {
              openDateRangeModal()
              //window.open('excel/youtube-history.xlsx')
            }}>Export คะแนนจากวิดีทัศน์ทั้งหมด</Button>
          </Flex>

          <Card>
            <CardHeader>
              <Flex align="center">
              <Image
                  src={ConfigureSVG}
                  w="34px"
                  h="34px"
                  me="14px"
                  alignSelf="center"
                  justifySelf="center"
                />
                <Flex direction="column">
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    สถานะ
                  </Text>
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex direction="column" mt="16px">
                <FormControl>
                  <RadioGroup value={statusList} onChange={(status)=>{
                    setCurrentPage(0)
                    setStatusList(status)
                  }}>
                    <Flex direction={'row'}>
                      <Radio ml={2} value="all">
                        ทั้งหมด
                      </Radio>
                      <Radio ml={6} value="draft">
                        ร่าง
                      </Radio>
                      <Radio ml={6} value="published">
                        เผยแพร่
                      </Radio>
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </Flex>
            </CardBody>
          </Card>

          <GradeLevelSelectionCard setSelectedGradeLevels={(levels)=>{
            setSelectedGradeLevels(levels)
            setCurrentPage(0)
            console.log("do 1")
          }} />
          <SubjectSelectionCard setSelectedSubjectIDs={(subjectIds)=>{
            setSelectedSubjectIDs(subjectIds)
            setCurrentPage(0)
            console.log("do 2")
          }} />

          
        </Stack>
      </Grid>

      <DateRangeModal
        isOpen={isDateRangeModalOpen}
        onClose={closeDateRangeModal}
        onSelectDates={handleSelectDates}
        isLoading={downloading}
      />
      
    </Flex>
  );
}

export default YoutubeList;
