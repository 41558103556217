import axios from 'axios';
import Swal from 'sweetalert2';

export const loadAllQuestionItem = async (question_id) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/load_question_item`, { question_id });
    const res = response.data;
    if (res && res.status === "success") {
      return res.item;
    }
    return null;
  };

export const loadQuestion = async (question_id, question_type) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/load_question`, { question_id });
    const res = response.data;
    if (res && res.status === "success") {
      const question = res.question;

      if (question_type === "fill") {
        const items = await loadAllQuestionItem(question_id);
        question.answers = items;
      }
      if (question_type === "choice") {
        const items = await loadAllQuestionItem(question_id);
        question.choices = items;
      }
      return question;
    }
    return null;
  };