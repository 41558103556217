import React, { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from 'auth/AuthProvider';
import { getParam } from 'util/common';
import { useHistory } from 'react-router-dom';

const Login = () => {
    const { user, login, logout } = useAuth();
    const history = useHistory();

    const checkLogin = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/auth/isLogin`, {}, {withCredentials: true}).then((response) => {
            //console.log(response)
            //alert('you are currently logged in')
            history.push(`/user/class-model-admin`);
        }).catch(
            function (error) {
              alert(error)
            }
          )
        
    }

    const autoLogin = () => {
        const stamp = getParam('stamp');
        const uuid = getParam('uuid');

        console.log(`stamp: ${stamp}`)
        console.log(`uuid: ${uuid}`)
        console.log(`${process.env.REACT_APP_API_URL}/auth/admin_auto_login`)

        axios.post(`${process.env.REACT_APP_API_URL}/auth/admin_auto_login`, {
            stamp: stamp,
            uuid: uuid
        }, {withCredentials: true})
        .then((response) => {
            console.log(response)
            const res = response.data;
            if (res.status === "success") {
                //window.location.href = "model_subject.html";
                console.log(res.user)
                login(res.user)
                checkLogin()
            } else {
                Swal.fire("Error!", `กรุณาตรวจสอบอีเมลและรหัสผ่านอีกครั้ง!`, 'warning');
            }
        })
        .catch((error) => {
            Swal.fire("Error!", 'ข้อมูลในการ login ไม่ถูกต้อง', 'warning');
        });
    };

    useEffect(() => {
        autoLogin();
    }, []); // Empty dependency array to run on component mount

    return (
        <div>
            {/* Your component JSX goes here */}
        </div>
    );
};

export default Login;
