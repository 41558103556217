import axios from 'axios';
import Swal from 'sweetalert2';

export const loadLessons = (lesson_id) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/activity_group/list_all_groups_with_activities`, {
        lesson_id
    }, { withCredentials: true })
    .then(function (response) {
        const res = response.data;
        if (res !== undefined && res.status === "success") {
            const groups = res.groups;
            return groups;
        } else {
            return [];
        }
    })
    .catch(function (error) {
        Swal.fire('Error!', `${error}`, 'warning');
        return [];
    });
};
