import axios from 'axios';

export const saveYoutube = async (info) => {
    //console.log(`info -> ${JSON.stringify(info)}`)
    try {
        if(info.id){
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/youtube/update_youtube_data`, {youtubeData: info});
            if (response.data.status === "success") {
                return response.data
            } else {
                console.log('Failed to fetch subjects');
                return response.data
            }
        }
        else{
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/youtube/insert_youtube_data`, {youtubeData: info});
            if (response.data.status === "success") {
                return response.data
            } else {
                console.log('Failed to fetch subjects');
                return response.data
            }
        }
    } catch (error) {
        console.error('Error fetching subjects:', error);
        return {status: "error"}
    }
};

export const loadYoutubeByYID = async (yid) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/youtube/fetch_youtube_data_by_yid`, {yid});
        if (response.data.status === "success") {
            return {status: "success", data: response.data}
        } else {
            console.log('Failed to fetch youtube');
            return {status: "error"}
        }
    } catch (error) {
        console.error('Error fetching youtube:', error);
        return {status: "error"}
    }
};

export const checkRedundantYoutubeID = async (yid) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/youtube/check_youtube_id`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ youtubeID: yid }),
          });
        return response;
    } catch (error) {
        console.error('Error fetching youtube:', error);
        throw error;
    }
};

export const loadYoutube = async (vid) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/youtube/fetch_youtube_data`, {vid});
        if (response.data.status === "success") {
            return {status: "success", data: response.data}
        } else {
            console.log('Failed to fetch youtube');
            return {status: "error"}
        }
    } catch (error) {
        console.error('Error fetching youtube:', error);
        return {status: "error"}
    }
};

export const deleteYoutube = async (id) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/youtube/delete_youtube`, {id});
        if (response.data.status === "success") {
            return {status: "success", data: response.data}
        } else {
            console.log('Failed to fetch youtube');
            return {status: "error"}
        }
    } catch (error) {
        console.error('Error fetching youtube:', error);
        return {status: "error"}
    }
};

export const loadAllYoutubes = async (subject_id, class_code, status, search, page, limit) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/youtube/list_all_youtubes`, {subject_id, class_code, status, search, page, limit});
        if (response.data.status === "success") {
            console.log(`total -> ${response.data.totalRecords}`)
            return {status: "success", data: response.data.result, total: response.data.totalRecords}
        } else {
            console.log('Failed to fetch youtube list');
            return {status: "error"}
        }
    } catch (error) {
        console.error('Error fetching youtube list:', error);
        return {status: "error"}
    }
};