import {
  Flex,
  Text,
  Stack,
  Button,
  Icon,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  RadioGroup,
  Radio,
  Checkbox,
  Box,
  Spinner,
  useToast,
  IconButton,
} from '@chakra-ui/react';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { getSubjects } from 'apis/subjectAPI';
import Card from 'components/Card/Card';
import { FaArrowDown, FaArrowUp, FaBookOpen, FaEye, FaFileImport, FaKey, FaUser } from 'react-icons/fa';
import { HSeparator, VSeparator } from 'components/Separator/Separator';
import { showWarningToast } from 'util/alertUtil';
import { showSuccessToast } from 'util/alertUtil';
import Swal from 'sweetalert2';
import { IoTrashBinOutline } from 'react-icons/io5';
import Breadcrumbs from 'views/MyIpstClass/widgets/Breadcrumbs';
import { showSuccessDialog } from 'util/alertUtil';
import { useAuth } from 'auth/AuthProvider';

const Classroom = () => {
  const bgCard = useColorModeValue("blue.500", 'navy.800');
  const bgTable = useColorModeValue("gray.50", 'navy.800');
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [classroomData, setClassroomData] = useState([]);
  const [classID, setClassID] = useState();
  const [className, setClassName] = useState();
  const [joincode, setJoincode] = useState();
  const [lessonNum, setLessonNum] = useState();

  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [lessons, setLessons] = useState([]);

  const [selectedSubectID, setSelectedSubjectID] = useState();
  const [selectedClassID, setSelectedClassID] = useState();

  const [selectedLessons, setSelectedLessons] = useState([]);
  const [exerciseType, setExerciseType] = useState('all');

  const [isSubjectsLoading, setSubjectsLoading] = useState(false);
  const [isClassesLoading, setClassesLoading] = useState(false);
  const [isLessonsLoading, setLessonsLoading] = useState(false);
  const [isImportLoading, setImportLoading] = useState(false);
  const [isStudentLoading, setStudentLoading] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { role } = useAuth();
  const toast = useToast()

  const breadcumLinks = [
    { url: '/#/user/classroom-list', text: 'รายชื่อชั้นเรียน' },
    { url: undefined, text: `${searchParams.get('cname')}` }
  ];

  const fetchLessonNum = () => {
    const classroom_id = searchParams.get('cid');
    axios.post(`${process.env.REACT_APP_API_URL}/classroom_detail/lesson_model_num`, { classroom_id }, { withCredentials: true })
    .then(response => {
      if (response.data.status === 'success') {
        setLessonNum(response.data.lesson_num);
      }
    })
    .catch(error => {
      console.error("There was an error fetching the classroom data!", error);
    });
  }

  useEffect(() => {
    const classroom_id = searchParams.get('cid');
    const classroom_name = searchParams.get('cname');
    const joincode = searchParams.get('joincode');

    setClassID(classroom_id);
    setClassName(classroom_name);
    setJoincode(joincode);

    fetchLessonNum()

    axios.post(`${process.env.REACT_APP_API_URL}/classroom_detail/list_all_students_in_classroom`, { selected_classroom_id: classroom_id }, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          console.log(JSON.stringify(response.data.result))
          setClassroomData(response.data.result);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the classroom data!", error);
      })
      .finally(()=>{
        setStudentLoading(false)
      })

  }, []);

  useEffect(() => {
    fetchSubjects();
    fetchClasses();
  }, []);

  useEffect(()=>{
    if(selectedSubectID && selectedClassID){
      fetchLessons(selectedSubectID, selectedClassID)
    }
  },[selectedSubectID, selectedClassID])

  const fetchSubjects = async () => {
    setSubjectsLoading(true);
    try {
      const subs = await getSubjects();
      setSubjects(subs);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    } finally {
      setSubjectsLoading(false);
    }
  };

  const fetchClasses = async () => {
    setClassesLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subjects/list_all_classes`, {}, { withCredentials: true });
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    } finally {
      setClassesLoading(false);
    }
  };

  const fetchLessons = async (subjectId, classId) => {
    setLessonsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/classroom_detail/list_lesson_model`, 
        { subject_id: subjectId, class_id: classId }, { withCredentials: true });
      setLessons(response.data.lessons);
    } catch (error) {
      console.error('Error fetching lessons:', error);
    } finally {
      setLessonsLoading(false);
    }
  };

  const handleLessonSelect = (lessonId) => {
    setSelectedLessons(prev =>
      prev.includes(lessonId)
        ? prev.filter(id => id !== lessonId)
        : [...prev, lessonId]
    );
  };

  const handleImport = async () => {
    if (selectedLessons.length === 0) {
      showWarningToast(toast, "แจ้งเตือน", 'กรุณาเลือกบทเรียนที่ต้องการนำเข้า');
      return;
    }
    console.log(`selectedLessons: ${selectedLessons}`);

    setImportLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/copy/clone_multiple_lesson`, 
        { lesson_ids: JSON.stringify(selectedLessons), classroom_id: classID }, 
        { withCredentials: true }
      );
      if (response.data.status === 'success') {
        onClose();
        setSelectedLessons([]);
        showSuccessDialog("นำเข้าบทเรียนเรียบร้อย!", "คุณสามารถดูบทเรียนที่นำเข้าแล้วได้โดยคลิกที่ปุ่ม 'แสดงบทเรียน'");
        fetchLessonNum()
      }
    } catch (error) {
      console.error('Error importing lessons:', error);
    } finally {
      setImportLoading(false);
    }
  };

  const isUniqueLesson = (cur_lesson) => {
    var count = 0;
    lessons.forEach(l => {
       if(l['lesson_name'] == cur_lesson){
        count++;
       }
    });

    return (count > 1)?false:true;
  }

  const getName = (c) => {
    var is_lesson_unique = isUniqueLesson(c['lesson_name']);
    var book_name = (!is_lesson_unique)?` (${c['book_name']})`:"";

    if(!c.ipst_classroom_id){
        if((exerciseType == "all" || exerciseType == "ipst")){
            return c['lesson_name']+book_name;
        }
    }
    else{
        if((exerciseType == "all" || exerciseType == "teacher")){
            return c['lesson_name']+" ("+c.classroom_name+")";
        }
    }
    return c['lesson_name'];
  }

  const handleDeleteStudent = (student_id, student_name) => {
    Swal.fire({
      title: 'ยืนยันลบ',
      text: `คุณต้องการลบ "${student_name}" ใช่หรือไม่`,
      showCancelButton: true,
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ยกเลิก',
      denyButtonColor: "#6CB4EE",
      confirmButtonColor: "#ED0A3F",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStudentClass(classID, student_id);
      }
    });
  };

  const handleSwapOrder = async (student_classroom_id1, student_classroom_id2, order_num1, order_num2) => {
    //alert(`${student_classroom_id1} ${student_classroom_id2} ${order_num1} ${order_num2}`)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/student_class/swap_student_order`,
        { student_classroom_id1, student_classroom_id2, order_num1, order_num2 },
        { withCredentials: true }
      );

      if (response.data.status === 'success') {
        // After successfully swapping, re-fetch the student data to update the list
        const updatedData = [...classroomData];
        const index1 = updatedData.findIndex((item) => item.id === student_classroom_id1);
        const index2 = updatedData.findIndex((item) => item.id === student_classroom_id2);

        if (index1 !== -1 && index2 !== -1) {
          // Swap the two items in the array
          [updatedData[index1], updatedData[index2]] = [updatedData[index2], updatedData[index1]];
          setClassroomData(updatedData);
          showSuccessToast(toast, "สำเร็จ!", "สลับตำแหน่งเรียบร้อย");
        }
      } else {
        showWarningToast(toast, "Error", "Unable to swap the order");
      }
    } catch (error) {
      console.error('Error swapping order:', error);
      showWarningToast(toast, "Error", "Cannot connect to the server");
    }
  };

  const deleteStudentClass = async (classroom_id, student_id) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/student_class/delete_student_class`, 
        { classroom_id, student_id }, 
        { withCredentials: true }
      );
      if (response.data.status === "success") {
        setClassroomData((prev) => prev.filter((student) => student.user_id !== student_id));
        Swal.fire('สำเร็จ!', "ลบเรียบร้อย!", 'success');
      } else {
        Swal.fire('Error!', "ไม่สามารถลบได้!", 'warning');
      }
    } catch (error) {
      console.error("There was an error deleting the student!", error);
      Swal.fire('Error!', "Cannot connect to the server!", 'warning');
    }
  };

  const updateStudentGroupNumber = async (student_id, student_group) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/student_class/update_student_group_number`, {
        ipst_classroom_id: classID,
        user_id: student_id,
        group_num: student_group,
      },{ withCredentials: true });
  
      if (response.data && response.data.status === 'success') {
        Swal.fire('สำเร็จ!', 'อัพเดทกลุ่มเรียบร้อย!', 'success');
      }
    } catch (error) {
      Swal.fire('Error!', 'Cannot connect to the server!', 'warning');
    }
  };
  
  const GroupDropdown = (studentID, defaultGroup="NULL") => {
    return (
      <Box width="200px">
        <Select 
          key={`std_${studentID}`} 
          defaultValue={defaultGroup || ''} 
          placeholder="เลือกกลุ่ม"
          onChange={(e)=>{
            updateStudentGroupNumber(studentID, e.target.value)
          }}
        >
          {Array.from({ length: 10 }, (_, i) => (
            <option key={i + 1} value={`${i + 1}`}>
              กลุ่ม {i + 1}
            </option>
          ))}
        </Select>
      </Box>
    );
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '100px' }}>
      <Breadcrumbs links={breadcumLinks} />
      <Card mt={1} bg={bgCard} px="0px">
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          px="22px"
        >
          {/*<Flex align="center">
            <Icon as={AiOutlineSearch} color="white" w="20px" h="20px" me="8px" />
            <Input
              _placeholder={{ color: 'white' }}
              placeholder="ค้นหานักเรียน..."
              border={{}}
              _hover={{}}
              _focus={{}}
              color="white"
            />
          </Flex>*/}
          <Text fontSize={"xl"} color={"white"}>{className}</Text>
          <Stack direction="row" spacing="10px" align="center">
            <Button fontWeight={500} size='sm' variant='outline' leftIcon={<FaFileImport />} onClick={onOpen}>
              นำเข้าบทเรียน
            </Button>
            <Button fontWeight={500} size='sm' variant='outline' onClick={() => {
              history.push(`class-model-admin?classroom_id=${classID}&cname=${searchParams.get('cname')}&joincode=${searchParams.get('joincode')}`)
            }} leftIcon={<FaEye />}>
              แสดงบทเรียน
            </Button>
          </Stack>
        </Flex>
        <HSeparator my="5px" />
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          justify="space-between"
          align="center"
          mb="10px"
          px="22px"
        >
          <Flex align="center">
            <Icon as={FaUser} mr={2} color="white" w='15px' h='15px' />
            <Text color="gray.100">นักเรียน</Text>
            <Text color="white" mx="10px">{classroomData.length}</Text>
            <Text color="gray.100">คน</Text>
          </Flex>
          <VSeparator h="30px" bg="white" display={{ sm: 'none', md: 'block' }} />
          <Flex align="center">
            <Icon as={FaBookOpen} mr={2} color="white" w='15px' h='15px' />
            <Text color="gray.100">บทเรียน</Text>
            <Text color="white" mx="10px">{lessonNum}</Text>
            <Text color="gray.100">บท</Text>
          </Flex>
          <VSeparator h="30px" bg="white" display={{ sm: 'none', md: 'block' }} />
          <Flex align="center">
            <Icon as={FaKey} mr={2} color="white" w='15px' h='15px' />
            <Text color="gray.100">รหัสเข้าร่วม</Text>
            <Text color="white" mx="10px">{joincode}</Text>
          </Flex>
        </Flex>

        <Flex px={5} mx={2} bgColor={bgTable} justify={"center"}>
          {!isStudentLoading && <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th p={0} color="gray.400" borderColor={borderColor}>
                  รหัส
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  ชื่อนักเรียน
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  อีเมล
                </Th>
                <Th color="gray.400" borderColor={borderColor}>
                  กลุ่ม
                </Th>
                <Th p={0} w="30px" color="gray.400" borderColor={borderColor}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody pb="0px" >
              {classroomData.map((row, index) => (
                <Tr key={index}>
                  <Td p={0}>{row.user_id}</Td>
                  <Td>{row.displayName}</Td>
                  <Td>{row.email}</Td>
                  {role == "STUDENT" && <Td>{row.group_num}</Td>}
                  {role != "STUDENT" && <Td>{GroupDropdown(row.user_id, row.group_num)}</Td>}
                  <Td p={0} w={"150px"}>
                    <IconButton
                      size="sm" 
                      color={"red"} 
                      mr={1} 
                      icon={<IoTrashBinOutline />}
                      onClick={() => handleDeleteStudent(row.user_id, row.displayName)} />

                    <IconButton
                      size="sm"
                      colorScheme="blue"
                      mr={1} 
                      onClick={() => handleSwapOrder(row.id, classroomData[index - 1].id, row.order_num, classroomData[index - 1].order_num)}
                      isDisabled={index === 0}
                      icon={<FaArrowUp />}
                    />

                    <IconButton
                      size="sm"
                      colorScheme="blue"
                      onClick={() => handleSwapOrder(row.id, classroomData[index + 1].id, row.order_num, classroomData[index + 1].order_num)}
                      isDisabled={index === classroomData.length - 1}
                      icon={<FaArrowDown />}
                    />
                  </Td>
                </Tr>
              ))}

              {classroomData.length == 0 && <Tr><Td colSpan={4}><Flex w={"100%"} justify={"center"}>ไม่มีข้อมูลนักเรียน</Flex></Td></Tr>}
            </Tbody>
          </Table>}
          {isStudentLoading && <Spinner mt={5} />}
        </Flex>

        <Flex
          direction={{ sm: 'column', lg: 'row' }}
          justify="space-between"
          align="center"
          my="20px"
          px="22px"
        >
        </Flex>
      </Card>

      <Modal closeOnOverlayClick={false} size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>นำเข้าบทเรียน</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl as="fieldset" mt={4}>
              {/*<FormLabel as="legend">ประเภทของแบบฝึกหัด</FormLabel>*/}
              <RadioGroup defaultValue={exerciseType} onChange={setExerciseType}>
                <Stack direction="row">
                  <Radio value="all">บทเรียนทั้งหมด</Radio>
                  <Radio value="ipst">บทเรียน สสวท.</Radio>
                  <Radio value="teacher">บทเรียนของอาจารย์</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            
            <FormControl id="subject" mt={4}>
              <FormLabel>วิชา</FormLabel>
              {isSubjectsLoading ? (
                <Spinner size="sm" />
              ) : (
                <Select placeholder="เลือกวิชา" onChange={(e) => {
                  //fetchLessons(e.target.value, classID)
                  setSelectedSubjectID(e.target.value)
                }}>
                  {subjects.map((subject, index) => (
                    <option key={index} value={subject.id}>{subject.subjectThai}</option>
                  ))}
                </Select>
              )}
            </FormControl>
            
            <FormControl id="classLevel" mt={4}>
              <FormLabel>ระดับชั้น</FormLabel>
              {isClassesLoading ? (
                <Spinner size="sm" />
              ) : (
                <Select placeholder="เลือกระดับชั้น" onChange={(e) => {
                  //fetchLessons(subjects[0]?.id, e.target.value)
                  setSelectedClassID(e.target.value)
                }}>
                  {classes.map((cls, index) => (
                    <option key={index} value={cls.id}>{cls.name_thai}</option>
                  ))}
                </Select>
              )}
            </FormControl>

            <Box overflowY="scroll" maxHeight="300px" mt={4} css={{
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}>
              {isLessonsLoading &&  <Spinner size="lg" />}
              
              {!isLessonsLoading && lessons.length > 0 && <Table variant="simple" color={textColor}>
                  <Thead>
                    <Tr>
                      <Th>เลือก</Th>
                      <Th>ชื่อบทเรียน</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {lessons.map((lesson, index) => (
                      <Tr key={index}>
                        <Td>
                          <Checkbox
                            isChecked={selectedLessons.includes(lesson.lesson_model_id)}
                            onChange={() => handleLessonSelect(lesson.lesson_model_id)}
                          />
                        </Td>
                        <Td>{getName(lesson)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>}
                {!isLessonsLoading && subjects.length > 0 && classes.length > 0 && lessons.length <= 0 && <Text>ไม่พบบทเรียน</Text>}
            </Box>
            
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" disabled={isImportLoading} variant="solid" onClick={handleImport} isLoading={isImportLoading}>
              นำเข้า
            </Button>
            <Button ml={3} onClick={()=>{
              setLessons([])
              onClose()
            }} disabled={isImportLoading}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Classroom;
