import { ArrowRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import Editor from 'components/Editor/Editor';
import React, { useRef, useState, useEffect } from 'react';
import { BiBookContent, BiNote, BiRightArrow, BiVideo } from 'react-icons/bi';
import { FaImage, FaImages, FaLastfm, FaYoutube } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';
import QuestionBuilder from './QuestionBuilder';
import QuestionPreview from './QuestionPreview';
import { SiContentful } from 'react-icons/si';
import { secondsToMMSS } from 'util/youtubeUtil';
import { shortenText } from 'util/youtubeUtil';
import { getVideoDetails } from 'util/youtubeUtil';
import { confirmDeleteDialog } from 'util/alertUtil';
import { showWarningToast } from 'util/alertUtil';
import { getSubjects } from 'apis/subjectAPI';
import { EDUCATION_LEVEL_OPTIONS } from 'variables/constant';
import { saveYoutube, loadYoutube } from 'apis/youtubeAPI';
import { TimeWidget } from './widgets/TimeWidget';
import TimeSelectorModal from './modals/TimeSelectorModal';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from './widgets/Breadcrumbs';
import { showSuccessDialog } from 'util/alertUtil';
import { showSuccessToast } from 'util/alertUtil';
import { showErrorToast } from 'util/alertUtil';
import DateRangeModal from './modals/DateRangeModal';
import { exportCSV } from 'util/common';
import moment from 'moment';
import QuestionYoutube from './widgets/QuestionYoutube';
import Swal from 'sweetalert2';
import { checkRedundantYoutubeID } from 'apis/youtubeAPI';
import { showWarningDialog } from 'util/alertUtil';

const YoutubeExercise = () => {
  const [id, setID] = useState();
  const [tempURL, setTempURL] = useState();
  const [youtubeURL, setYoutubeURL] = useState();
  const [youtubeID, setYoutubeID] = useState();
  const [youtubeDuration, setYoutubeDuration] = useState();
  const [youtubeTitle, setYoutubeTitle] = useState();
  const [youtubeThumbURL, setYoutubeThumbURL] = useState();
  const [youtubeDescription, setYoutubeDescription] = useState();
  const [betweenQuestions, setBetweenQuestions] = useState();
  const [endQuestions, setEndQuestions] = useState();
  const [publicStatus, setPublicStatus] = useState("draft")
  const [selectedSubject, setSelectedSubject] = useState()
  const [selectedClass, setSelectedClass] = useState()

  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [progressInterval, setProgressInterval] = useState(null);
  const history = useHistory();
  const [fetchingYoutube, setFetchingYoutube] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [endQuestionTimeModalOpen, setEndQuestionTimeModalOpen] = useState(false);
  const [endQuestionTime, setEndQuestionTime] = useState()

  const [isSaving, setIsSaving] = useState(false)

  const bgButtonGroup = useColorModeValue('gray.50', 'navy.700');
  const bgActiveButton = useColorModeValue('blue.100', 'gray.200');
  const textColor = useColorModeValue('gray.700', 'white');
  const [activeButton, setActiveButton] = useState({
    youtubeInfo: true,
    content: false,
    endClip: false,
    preview: false
  });

  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { isOpen: isDateRangeModalOpen, onOpen: openDateRangeModal, onClose: closeDateRangeModal } = useDisclosure();
  const [downloading, setDownloading] = useState(false)

  const breadcumLinks = [
    { url: '/#/user/youtube-list', text: 'รายการวีดิทัศน์' },
    { url: undefined, text: 'สร้าง/แก้ไข Youtube' }
  ];


  const handleClose = () => {
    setIsOpen(false);
  }

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: { rel: 0, iv_load_policy: 3 }
  };

  useEffect(() => {
    const handleUnload = () => {
      clearInterval(progressInterval);
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleUnload);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [progressInterval]);

  async function loadYoutubeInfo(vid) {
    setIsSaving(true)
    //const vid = searchParams.get('vid');
    const youtube = await loadYoutube(vid);
    setIsSaving(false)

    if (youtube.status == "success") {
      const y = youtube.data.result.video;
      const betweenQs = youtube.data.result.between_questions
      const endQs = youtube.data.result.end_questions
      //console.log(JSON.stringify(y))

      setID(y.id)
      setYoutubeID(y.youtube_id)
      setYoutubeTitle(y.title)
      setYoutubeURL(y.url)
      setTempURL(y.url)
      setYoutubeThumbURL(y.thumbnail_url)
      setSelectedSubject(y.subject_id)
      setSelectedClass(y.class)
      setYoutubeDescription(y.description)
      setPublicStatus(y.status)
      setYoutubeDuration(y.duration);
      setEndQuestionTime(y.end_question_display_time)

      setBetweenQuestions(betweenQs)
      setEndQuestions(endQs)
      //console.log("y --> "+JSON.stringify(y))
    }
    else {
      Swal.fire("แจ้งเตือน", `ไม่สามารถโหลด Youtube รหัส ${vid} ได้`, "warning")
    }
  }

  useEffect(() => {

    async function loadSubjects() {
      const subs = await getSubjects()
      setSubjects(subs)

      const vid = searchParams.get('vid');
      if(vid){
        loadYoutubeInfo(vid)
      }
    }

    loadSubjects();

  }, [])

  useEffect(() => {
    const unlisten = history.listen(() => {
      clearInterval(progressInterval);
    });

    return () => {
      unlisten();
    };
  }, [history, progressInterval]);

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const onPlay = () => {
    trackProgress();
  };

  const onStateChange = (event) => {
    if (event.data === 1) {
      // Video is playing
      console.log('Video is playing');
    } else {
      // Video is paused or ended
      console.log('Video is paused or ended');
      clearInterval(progressInterval);
    }
  };

  const trackProgress = () => {
    // Set an interval to update current time every second
    const intervalId = setInterval(() => {
      const currentTime = playerRef.current.getCurrentTime();
      setCurrentTime(currentTime);
      const targetTime = 5; // Target time in seconds
      if (currentTime >= targetTime) {
        //console.log('Video reached 5 seconds');
        // Perform actions when video reaches certain time
        //clearInterval(intervalId); // Stop tracking progress
      }
      console.log(`curtime: ${currentTime}`);
    }, 1000); // Update every second
    setProgressInterval(intervalId); // Save intervalId to state
  };

  function getYouTubeInfo(url) {
    // Regular expression to match YouTube video ID
    setFetchingYoutube(true);
    if (url) {
      const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  
      // Extract video ID from URL
      const match = url.match(regExp);
      const videoId = match && match[1];
  
      if (!videoId) {
        // Invalid YouTube URL
        setFetchingYoutube(false);
        showWarningToast(toast, "ไม่สามารถดึงข้อมูลจาก URL ได้", "กรุณาตรวจสอบ URL");
        resetYouTubeData();
      } else {
        // Call API to check if video ID is redundant
        checkRedundantVideoID(videoId)
          .then((response) => {
            if (response.exists) {
              // Video ID is redundant
              setFetchingYoutube(false);
              showWarningDialog("ไม่สามารถใช้วิดีโอนี้ได้", "วิดีโอนี้มีอยู่แล้วในระบบ")
              resetYouTubeData();
            } else {
              // Continue fetching video details
              const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
              setYoutubeID(videoId);
              setYoutubeThumbURL(thumbnailUrl);
              setYoutubeURL(url);
  
              getVideoDetails(videoId)
                .then((videoInfo) => {
                  setYoutubeDuration(videoInfo.duration);
                  setYoutubeTitle(videoInfo.title);
                  setYoutubeDescription(videoInfo.description);
                  setFetchingYoutube(false);
                })
                .catch((error) => {
                  showWarningToast(toast, "ไม่สามารถดึงข้อมูลจาก URL ได้", "กรุณาตรวจสอบ URL");
                  console.error('Error:', error);
                  setFetchingYoutube(false);
                });
            }
          })
          .catch((error) => {
            // Error in checking redundancy
            console.error('Error checking video redundancy:', error);
            setFetchingYoutube(false);
            showWarningToast(toast, "เกิดข้อผิดพลาด", "ไม่สามารถตรวจสอบวิดีโอได้");
          });
      }
    } else {
      resetYouTubeData();
      setFetchingYoutube(false);
      showWarningToast(toast, "ไม่สามารถดึงข้อมูลจาก URL ได้", "กรุณาตรวจสอบ URL");
    }
  }
  
  // Helper function to reset YouTube data
  function resetYouTubeData() {
    setYoutubeID(null);
    setYoutubeThumbURL(null);
    setYoutubeURL(null);
    setYoutubeDuration(null);
    setYoutubeTitle(null);
    setYoutubeDescription(null);
  }
  
  // Function to check if the YouTube video ID is redundant
  async function checkRedundantVideoID(videoId) {
    const response = await checkRedundantYoutubeID(videoId)
  
    if (!response.ok) {
      throw new Error('Failed to check YouTube ID redundancy');
    }
  
    return await response.json(); // Expecting { exists: true/false } in response
  }  

  /*function getYouTubeInfo(url) {
    // Regular expression to match YouTube video ID
    setFetchingYoutube(true)
    if (url) {
      const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

      // Extract video ID from URL
      const match = url.match(regExp);
      const videoId = match && match[1];

      if (!videoId) {
        //throw new Error('Invalid YouTube URL');
        setFetchingYoutube(false)
        showWarningToast(toast, "ไม่สามารถดึงข้อมูลจาก URL ได้", "กรุณาตรวจสอบ URL")
        setYoutubeID(null);
        setYoutubeThumbURL(null);
        setYoutubeURL(null);
        setYoutubeDuration(null)
        setYoutubeTitle(null)
        setYoutubeDescription(null)
      }
      else {
        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
        setYoutubeID(videoId);
        setYoutubeThumbURL(thumbnailUrl);
        setYoutubeURL(url);

        getVideoDetails(videoId)
          .then((videoInfo) => {
            console.log('Video ID:', videoInfo.id);
            console.log('Title:', videoInfo.title);
            console.log('Thumbnail URL:', videoInfo.thumbnailPath);
            console.log('Duration:', videoInfo.duration);
            console.log('Description:', videoInfo.description);
            console.log(videoInfo)

            setYoutubeDuration(videoInfo.duration)
            setYoutubeTitle(videoInfo.title)
            setYoutubeDescription(videoInfo.description)
            setFetchingYoutube(false)
          })
          .catch((error) => {
            showWarningToast(toast, "ไม่สามารถดึงข้อมูลจาก URL ได้", "กรุณาตรวจสอบ URL")
            console.error('Error:', error);
            setFetchingYoutube(false)
          });
      }
    } else {
      setYoutubeID(null);
      setYoutubeThumbURL(null);
      setYoutubeURL(null);
      setYoutubeDuration(null)
      setYoutubeTitle(null)
      setYoutubeDescription(null)
      setFetchingYoutube(false)
      showWarningToast(toast, "ไม่สามารถดึงข้อมูลจาก URL ได้", "กรุณาตรวจสอบ URL")
    }
  }*/

  const updateTitle = (v) => {
    setYoutubeTitle(v)
  }

  const handleSaveEndQuestionTime = (v) => {
    setEndQuestionTime(v)
  }

  function hasCorrectAnswer(questions) {
    if (questions) {
      const betweenQuestions = Array.isArray(questions.betweenQuestions) ? questions.betweenQuestions : [];
      const endQuestions = Array.isArray(questions.endQuestions) ? questions.endQuestions : [];
      
      for (const question of [...betweenQuestions, ...endQuestions]) {
        if (question && question.question_type === "choice" && Array.isArray(question.question_items)) {
          const hasCorrect = question.question_items.some(item => item.is_correct === true);
          if (!hasCorrect) {
            return false;
          }
        }
        else if (question && question.question_type === "fill" && Array.isArray(question.question_items)) {
          const hasIncorrect = question.question_items.some(item => item.text?.trim() === "");
          if (hasIncorrect) {
            return false;
          }
        }
      }
    }
    return true; // All questions have at least one correct answer
  }  

  /*function hasCorrectAnswer(questions) {
    if(questions){
      for (const question of [...questions.betweenQuestions, ...questions.endQuestions]) {
        if (question && question.question_type === "choice" && Array.isArray(question.question_items)) {
          const hasCorrect = question.question_items.some(item => item.is_correct === true);
          if (!hasCorrect) {
            return false;
          }
        }
        else if (question && question.question_type === "fill" && Array.isArray(question.question_items)) {
          const hasIncorrect = question.question_items.some(item => item.text?.trim() === "");
          if (hasIncorrect) {
            return false;
          }
        }
      }
    }
    return true; // All questions have at least one correct answer
  }*/

  function hasAppearTime(questions) {
    if(questions){
      //console.log(`betweenQuestions -> ${JSON.stringify(questions)}`)
      for (const question of questions) {
          if (!question.display_time || question.display_time.trim() === "") {
              return false;
          }
      }
    }
    return true;
  }

  const onSave = async () => {
    if (isSaving) return;

    if (!youtubeURL) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้ใส่ลิงค์ Youtube")
    }
    else if (!youtubeTitle) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้กรอกชื่อของสื่อ")
    }
    else if (!youtubeTitle) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้กรอกชื่อของสื่อ")
    }
    else if (!youtubeID) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้ระบุ Youtube ID")
    }
    else if (!youtubeThumbURL) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้ระบุภาพตัวอย่างสื่อ (thumbnail)")
    }
    else if (!selectedSubject) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้เลือกวิชา")
    }
    else if (!selectedClass) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้เลือกระดับชั้น")
    }
    else if (!publicStatus) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้เลือกสถานะ")
    }
    else if (!hasCorrectAnswer({ betweenQuestions, endQuestions })) {
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้เลือกคำตอบที่ถูกต้องสำหรับคำถามทุกคำถาม (กรณีคำถามแบบเติมคำ คำตอบต้องไม่เป็นค่าว่าง)")
    }
    else if (!hasAppearTime(betweenQuestions)){
      showWarningToast(toast, "กรุณากรอกข้อมูลให้ครบถ้วน", "คุณยังไม่ได้เลือกเวลาที่จะให้คำถามปรากฎ (คำถามระหว่างคลิป)")
    }
    else {
      //console.log(`id: ${id}`)
      //console.log(`betweenQuestions -> ${JSON.stringify(betweenQuestions)}`)

      setIsSaving(true)
      const data = await saveYoutube({
        id: id,
        title: youtubeTitle,
        url: youtubeURL,
        youtube_id: youtubeID,
        thumbnail_url: youtubeThumbURL,
        subject_id: selectedSubject,
        class: selectedClass,
        description: youtubeDescription,
        status: publicStatus,
        duration: youtubeDuration,
        between_questions: betweenQuestions,
        end_questions: endQuestions,
        end_question_display_time: endQuestionTime,
      })
      setIsSaving(false)
      //console.log(`betweenQuestions: ${betweenQuestions}`)

      if (data.status == "success") {
        setID(data.youtubeId)
        showSuccessToast(toast, "สำเร็จ!", "ระบบได้ทำการบันทึกแล้ว")
        loadYoutubeInfo(data.youtubeId)
      }
      else {
        showErrorToast(toast, "ไม่สำเร็จ!", "ไม่สามารถบันทึกได้")
      }
    }

  }

  const handleSelectDates = async ({ startDate, endDate }) => {
    await exportCSV('export_test_scores', moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), setDownloading, { exportType: "youtube", youtube_id: youtubeID })
    closeDateRangeModal();
    console.log("Selected dates:", startDate, endDate);
  };

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '100px' }}>
      <Breadcrumbs links={breadcumLinks} />
      <Card>
        <Flex
          direction={{ sm: 'column', md: 'row' }}
          justify="space-between"
          align={{ md: 'center' }}
          mb="16px"
        >
          <Text color={textColor} fontSize="lg" fontWeight="bold" mb={{ sm: '12px', md: '0px' }}>
            สื่อจาก Youtube
          </Text>
          <Flex align="center">
            <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
              <Button
                leftIcon={<BiVideo />}
                variant="no-effects"
                w={{ sm: 'fit-content', xl: '135px' }}
                h="40px"
                fontSize="xs"
                boxShadow={activeButton.youtubeInfo ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)' : 'none'}
                bg={activeButton.youtubeInfo ? bgActiveButton : 'transparent'}
                onClick={() =>
                  setActiveButton({
                    youtubeInfo: true,
                    content: false,
                    endClip: false,
                    preview: false
                  })
                }
              >
                เกี่ยวกับ VDO
              </Button>
              <Button
                leftIcon={<SiContentful />}
                isDisabled={youtubeURL != null ? false : true}
                variant="no-effects"
                w={{ sm: 'fit-content', xl: '135px' }}
                h="40px"
                fontSize="xs"
                boxShadow={activeButton.content ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)' : 'none'}
                bg={activeButton.content ? bgActiveButton : 'transparent'}
                onClick={() =>
                  setActiveButton({
                    youtubeInfo: false,
                    content: true,
                    endClip: false,
                    preview: false
                  })
                }
              >
                คำถามระหว่าง VDO
              </Button>
              <Button
                leftIcon={<BiBookContent />}
                isDisabled={youtubeURL != null ? false : true}
                variant="no-effects"
                w={{ sm: 'fit-content', xl: '135px' }}
                h="40px"
                fontSize="xs"
                boxShadow={activeButton.endClip ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)' : 'none'}
                bg={activeButton.endClip ? bgActiveButton : 'transparent'}
                onClick={() =>
                  setActiveButton({
                    youtubeInfo: false,
                    endClip: true,
                    content: false,
                    preview: false
                  })
                }
              >
                คำถามท้าย VDO
              </Button>
              <Button
                leftIcon={<BiBookContent />}
                isDisabled={youtubeURL != null ? false : true}
                variant="no-effects"
                w={{ sm: 'fit-content', xl: '135px' }}
                h="40px"
                fontSize="xs"
                boxShadow={activeButton.preview ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)' : 'none'}
                bg={activeButton.preview ? bgActiveButton : 'transparent'}
                onClick={() =>
                  setActiveButton({
                    youtubeInfo: false,
                    endClip: false,
                    content: false,
                    preview: true
                  })
                }
              >
                Preview
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          {activeButton.youtubeInfo && (
            <Flex direction={{ base: 'column', md: 'row' }} w="100%">
              <Flex
                justify={'center'}
                w={{ base: '100%', md: '50%' }}
                mr={2}
                mb={1}
                direction={'column'}
              >
                {youtubeID && (
                  <YouTube
                    videoId={youtubeID}
                    onReady={onReady}
                    onStateChange={onStateChange}
                    onPlay={onPlay}
                    opts={opts}
                    className={'youtubeContainer'}
                  />
                )}
                {youtubeID == null && (
                  <Flex
                    justify="center"
                    align="center"
                    w="100%"
                    maxW="550px"
                    minH="280px"
                    className="dashed-border"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                  >
                    {youtubeURL ? (
                      <Image src={youtubeURL} alt="Youtube" />
                    ) : (
                      <Icon as={FaYoutube} color={'gray.300'} boxSize={100} />
                    )}
                  </Flex>
                )}
                <Stack
                  p={2}
                  direction={{ sm: 'column', lg: 'row' }}
                  spacing={{ sm: '24px', lg: '30px' }}
                >
                  <FormControl>
                    <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                      ภาพตัวอย่างสื่อ (thumbnail)
                    </FormLabel>
                    <Flex>
                      <Box
                        minW="200px"
                        maxW="200px"
                        h="150px"
                        justify={"center"}
                        align={"center"}
                        className="dashed-border"
                        borderWidth="1px"
                        borderRadius="lg"
                        overflow="hidden"
                      >
                        {youtubeThumbURL ? (
                          <Image src={youtubeThumbURL} alt="Thumbnail" />
                        ) : (
                          <Center h="150px">
                            <Icon as={FaImage} color={'gray.300'} boxSize={100} />
                          </Center>
                        )}
                      </Box>

                      <Flex direction={'column'} w="50%" maxW="340px" ml={2}>
                        <Grid
                          templateColumns={'95px 1fr'}
                          templateRows={'repeat(2, auto)'}
                          rowGap={2}
                          gap="0px"
                        >
                          <Text borderColor="gray.100" borderWidth={2} p={2} fontSize={"sm"} bgColor="gray.50">Youtube ID:</Text>
                          <Text borderColor="gray.100" borderWidth={2} p={2} fontSize={"sm"}>{youtubeID ?? "ยังไม่ระบุ"}</Text>
                          <Text borderColor="gray.100" borderWidth={2} p={2} fontSize={"sm"} bgColor="gray.50">ความยาว:</Text>
                          <Text borderColor="gray.100" borderWidth={2} p={2} fontSize={"sm"}>{youtubeDuration ? `${secondsToMMSS(youtubeDuration)}` : "ยังไม่ระบุ"}</Text>
                          <Text borderColor="gray.100" borderWidth={2} p={2} fontSize={"sm"} bgColor="gray.50">สถานะ:</Text>
                          <Flex borderColor="gray.100" borderWidth={2} p={2} w="100%" align="center" justify="left">
                            <RadioGroup isDisabled={youtubeURL != null ? false : true} onChange={setPublicStatus} value={publicStatus}>
                              <Stack direction='row'>
                                <Radio value='draft'>ร่าง</Radio>
                                <Radio value='published'>เผยแพร่</Radio>
                              </Stack>
                            </RadioGroup>
                          </Flex>
                        </Grid>
                      </Flex>
                    </Flex>
                  </FormControl>
                </Stack>
              </Flex>
              <Flex w={{ base: '100%', md: '50%' }} ml={2}>
                <Stack direction="column" spacing="20px" w="100%">
                  <FormControl>
                    <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                      <Flex><Text color="red.400">*</Text><Text>ลิงค์ Youtube</Text></Flex>
                    </FormLabel>
                    {youtubeURL == null && (
                      <InputGroup>
                        <Input
                          fontSize="sm"
                          placeholder="วางลิงค์ youtube และกด 'ดึงข้อมูล'"
                          value={tempURL}
                          onChange={(e) => setTempURL(e.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            mr={1}
                            onClick={() => getYouTubeInfo(tempURL)}
                            isLoading={fetchingYoutube}
                          >
                            ดึงข้อมูล
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    )}
                    {youtubeURL != null && (
                      <InputGroup>
                        <Link color={textColor} href={youtubeURL} isExternal mr="4.5rem">
                          {shortenText(youtubeURL, 60)}
                        </Link>
                        <InputRightElement width="4.5rem">
                          <Button
                            variant="outline"
                            colorScheme="red"
                            h="1.75rem"
                            size="sm"
                            mr={1}
                            onClick={() => {
                              confirmDeleteDialog("ยืนยันลบ URL", "คุณต้องการลบ URL นี้ใช่หรือไม่", () => {
                                setYoutubeThumbURL(null);
                                setTempURL(null);
                                setYoutubeURL(null);
                                setYoutubeID(null);
                                setYoutubeDuration(null);
                                setYoutubeTitle('');
                                setYoutubeDescription(null);
                                setPublicStatus('draft');
                                setSelectedSubject(null)
                                setSelectedClass(null)
                              })
                            }}
                          >
                            ลบ URL
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    )}
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                      ชื่อของสื่อ
                    </FormLabel>
                    <Input value={youtubeTitle} variant="main" placeholder="เช่น การหารคูณร่วมน้อย" fontSize="sm" onChange={(e) => updateTitle(e.target.value)} />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="sm" fontWeight="bold" mb="10px">
                      คำอธิบายสื่อ
                    </FormLabel>
                    <Editor defaultValue={youtubeDescription} handleChange={(v) => { setYoutubeDescription(v) }} height={20} />
                  </FormControl>

                  <Flex direction={'row'}>
                    <FormControl mr={1}>
                      <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                        ระดับชั้นเรียน
                      </FormLabel>
                      <Select
                        variant="main"
                        placeholder="เลือก"
                        color="gray.800"
                        fontSize="md"
                        value={selectedClass}
                        onChange={(e) => setSelectedClass(e.target.value)}
                        isReadOnly
                      >
                        {EDUCATION_LEVEL_OPTIONS.map((e, i) => {
                          return <option value={e.value}>{e.label}</option>
                        })}
                      </Select>
                    </FormControl>

                    <FormControl ml={1}>
                      <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                        วิชา
                      </FormLabel>
                      <Select
                        variant="main"
                        placeholder="เลือก"
                        color="gray.800"
                        fontSize="md"
                        value={selectedSubject}
                        onChange={(e) => setSelectedSubject(e.target.value)}
                        isReadOnly
                      >
                        {subjects.map((s, index) => {
                          return <option value={s.id}>{s.subjectThai}</option>
                        })}
                      </Select>
                    </FormControl>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          )}

          {activeButton.content && (
            <Flex w="100%">
              <QuestionBuilder youtubeID={youtubeID} initailQuestions={betweenQuestions} updateQuestions={setBetweenQuestions} showTime={true} />
            </Flex>
          )}

          {activeButton.endClip && (
            <Flex direction={"column"} w="100%">
              <Flex>
                <Text>เวลาที่ต้องการแสดงคำถามท้ายวิดีทัศน์:</Text>
                <TimeWidget w={"80px"} mb={1} ml={1} value={endQuestionTime} onClick={() => {
                  setEndQuestionTimeModalOpen(true);
                }} />
              </Flex>
              <QuestionBuilder youtubeID={youtubeID} initailQuestions={endQuestions} updateQuestions={setEndQuestions} showTime={false} endQuestionTime={endQuestionTime} />
            </Flex>
          )}

          {activeButton.preview && (
            <Flex w="100%">
              {/*<QuestionPreview betweenQuestions={betweenQuestions} endQuestions={endQuestions} youtubeID={youtubeID} endQuestionTime={endQuestionTime} duration={youtubeDuration} />*/}
              {youtubeID && <QuestionYoutube yid={youtubeID} endQuestionTime={endQuestionTime} />}
            </Flex>
          )}

        </Flex>

        <Flex borderRadius={10} bgColor="gray.100" mt={5} align={'center'} justify="space-between" p={5}>
          <Button fontWeight={400} variant='ghost' onClick={() => {
            openDateRangeModal()
            //window.open('excel/youtube-history.xlsx')
          }}>Export คะแนน</Button>
          <Flex>
            <Button mr={2} variant="outline" colorScheme="white" w="100px" h="35px">
              ยกเลิก
            </Button>
            <Button isLoading={isSaving} colorScheme="blue" variant="solid" w="100px" h="35px" onClick={() => {
              onSave()
            }}>
              บันทึก
            </Button>
          </Flex>
        </Flex>
      </Card>

      <TimeSelectorModal
        initTime={endQuestionTime}
        isOpen={endQuestionTimeModalOpen}
        onClose={() => setEndQuestionTimeModalOpen(false)}
        onSave={handleSaveEndQuestionTime}
      />

      <DateRangeModal
        isOpen={isDateRangeModalOpen}
        onClose={closeDateRangeModal}
        onSelectDates={handleSelectDates}
        isLoading={downloading}
      />
    </Flex>
  );
};

export default YoutubeExercise;
