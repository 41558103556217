import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Flex,
  Text,
  Stack,
  Button,
  Icon,
  Input,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  IconButton,
  Checkbox,
  CheckboxGroup,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import {
  AiOutlineSearch,
} from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import Card from 'components/Card/Card';
import { HSeparator } from 'components/Separator/Separator';
import { useHistory } from 'react-router-dom';
import GradeLevelSelectionCard from './widgets/GradeLevelSelectionCard';
import { useAuth } from 'auth/AuthProvider';
import IPSTHeader from './widgets/IPSTHeader';
import { IoTrashBinOutline } from 'react-icons/io5';
import { FaEye } from 'react-icons/fa';
import ClassLevelSelectionCard from './widgets/ClassLevelSelectionCard';
import { showSuccessToast } from 'util/alertUtil';
import { showErrorToast } from 'util/alertUtil';
import { showWarningDialog } from 'util/alertUtil';
import Swal from 'sweetalert2';

const ClassroomList = () => {
  const [classroomData, setClassroomData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedGradeLevels, setSelectedGradeLevels] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [debouncedSearchKey, setDebouncedSearchKey] = useState('');
  const history = useHistory();
  const { user, role } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [joinCode, setJoinCode] = useState('');
  const [className, setClassName] = useState('');
  const [gradeLevel, setGradeLevel] = useState();
  const [year, setYear] = useState(new Date().getFullYear()); // Convert to Thai year
  const [term, setTerm] = useState('1');
  const [classes, setClasses] = useState([]);
  const [errors, setErrors] = useState({}); // Track errors
  const [editMode, setEditMode] = useState(false); // Track whether we are in edit mode
  const [editingClassId, setEditingClassId] = useState(null); // Store the ID of the class being edited
  const toast = useToast()

  const bgCard = useColorModeValue(
    'linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)',
    'navy.800'
  );

  useEffect(() => {
    fetchClasses();
  },[]);

  useEffect(() => {
    fetchClassroomData(currentPage + 1);
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchKey(searchKey);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchKey]);

  useEffect(() => {
    setCurrentPage(0);
    fetchClassroomData(0); // Fetch the first page when filters or search key changes
  }, [selectedGradeLevels, selectedYears, debouncedSearchKey]);

  const fetchClasses = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subjects/list_all_classes`, {}, { withCredentials: true });
      const c = response.data.classes;
      setClasses(c);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchClassroomData = (page) => {
    const api = (role !== "STUDENT") ? '/classroom/list_all_classroom_with_paginate' : '/student_class/list_all_student_classroom_with_paginate';
    axios.post(`${process.env.REACT_APP_API_URL}${api}`, 
      { page, limit: rowsPerPage, class_levels: selectedGradeLevels, years: selectedYears, search_key: debouncedSearchKey }, 
      { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          setClassroomData(response.data.result);
          setPageCount(response.data.totalPages);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the classroom data!", error);
      });
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleYearChange = (year) => {
    setSelectedYears(prevYears => {
      if (prevYears.includes(year)) {
        return prevYears.filter(y => y !== year);
      } else {
        return [...prevYears, year];
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchKey(e.target.value);
    setCurrentPage(0); // Reset to first page when search key changes
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(0); // Reset to first page when rows per page changes
  };

  const goToComponent = (data) => {
    if(role !== "STUDENT"){
      history.push(`classroom?cid=${data.id}&cname=${data.subject_name}&joincode=${data.join_code}`);
    }
    else{
      //different variables are to be used since fetching from different APIs
      history.push(`class-model-admin?classroom_id=${data.classroom_id}&cname=${data.subject_name}`);
    }
  };

  const getLast10Years = () => {
    const currentYear = new Date().getFullYear() + 1;
    return Array.from({ length: 11 }, (_, i) => currentYear - i);
  };

  const validateFields = () => {
    const newErrors = {};

    if (role === 'STUDENT' && !joinCode) {
      newErrors.joinCode = 'รหัสชั้นเรียนจำเป็นต้องกรอก';
    }

    if (role !== 'STUDENT') {
      if (!className) newErrors.className = 'ชื่อชั้นเรียนจำเป็นต้องกรอก';
      if (!gradeLevel) newErrors.gradeLevel = 'กรุณาเลือกระดับชั้น';
      if (!year) newErrors.year = 'กรุณาเลือกปีการศึกษา';
      if (!term) newErrors.term = 'กรุณาเลือกภาคการศึกษา';
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return; // Don't proceed if validation fails

    if (role === 'STUDENT') {
      console.log(`Joining class with code: ${joinCode}`);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/student_class/join_class`,
          { join_code: joinCode },
          { withCredentials: true }
        );
  
        if (response.data.status === 'success') {
          Swal.fire({
            title: 'สำเร็จ!',
            text: 'นำเข้าชั้นเรียนเรียบร้อย',
            icon: 'success',
            padding: '2em',
          }).then(() => {
            fetchClassroomData(currentPage + 1); // Reload the table data
            onClose(); // Close the modal
          });
        } else if (response.data.status === 'added_already') {
          Swal.fire('Warning', 'คุณได้เพิ่มชั้นเรียนนี้ไว้แล้ว', 'warning');
        } else if (response.data.status === 'time_invalid') {
          Swal.fire('Warning', 'ชั้นเรียนยังไม่เปิดใช้งาน', 'warning');
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'ไม่สามารถนำเข้าชั้นเรียนได้! กรุณาตรวจสอบรหัส.',
            icon: 'error',
            padding: '2em',
          }).then(() => {
            onClose(); // Close the modal
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: 'Cannot connect to the server!',
          icon: 'error',
          padding: '2em',
        }).then(() => {
          onClose(); // Close the modal
        });
      }
    } else {
      const apiUrl = `${process.env.REACT_APP_API_URL}/classroom/classroom_new_insert`;

      const data = {
          subject_name: className,
          class_id: gradeLevel,
          year: year,
          term: term,
          classroom_opt: editMode ? "update": "insert", // You might need to set this based on your form
          classroom_id: editMode ? editingClassId : null, // Set classroom_id if editing
      };

      try {
          const response = await axios.post(apiUrl, data, { withCredentials: true });

          if (response.data.status === "success") {
            showSuccessToast(toast, "สำเร็จ", "บันทึกข้อมูลเรียบร้อย")
            fetchClassroomData(currentPage + 1)
          } else {
            showWarningDialog(toast, "บันทึกไม่สำเร็จ", response.data.message || "There was an issue saving the data.")
          }
      } catch (error) {
        showErrorToast(toast, "บันทึกไม่สำเร็จ", "กรุณาลองใหม่ในภายหลัง")
      }
    }
    onClose();
  };

  const handleAddClassClick = () => {
    setEditMode(false); // Set to add mode
    clearForm(); // Clear the form inputs
    onOpen();
  };

  const handleEditClassClick = (classData) => {
    setEditMode(true); // Set to edit mode
    setEditingClassId(classData.id); // Store the ID of the class being edited
    setClassName(classData.subject_name);
    setGradeLevel(classData.class_id);
    setYear(classData.year);
    setTerm(classData.term);
    onOpen();
  };

  const clearForm = () => {
    setClassName('');
    setGradeLevel('');
    setYear(new Date().getFullYear());
    setTerm('1');
    setJoinCode('');
    setErrors({});
  };

  const handleDelete = async (data) => {
    var api = `${process.env.REACT_APP_API_URL}/classroom/delete_classroom`;
    if(role == "STUDENT"){
      api = `${process.env.REACT_APP_API_URL}/student_class/delete_class`;
    }

    const confirmed = await Swal.fire({
      title: 'คุณแน่ใจไหม? ',
      text: 'คุณจะไม่สามารถย้อนกลับได้หลังจากลบ!',
      icon: 'warning',
      showCancelButton: true,
      denyButtonColor: "#6CB4EE",
      confirmButtonColor: "#ED0A3F",
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ยกเลิก',
    });

    if (confirmed.isConfirmed) {
      try {
        const response = await axios.post(
          api, 
          { classroom_id: data.id }, 
          { withCredentials: true }
        );

        if (response.data.status === 'success') {
          Swal.fire('สำเร็จ!', 'ชั้นเรียนของคุณถูกลบเรียบร้อยแล้ว.', 'success');
          fetchClassroomData(currentPage + 1); // Reload the table data
        } else {
          Swal.fire('Error', 'ไม่สามารถลบชั้นเรียนได้ กรุณาลองใหม่', 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'การลบชั้นเรียนล้มเหลว!', 'error');
      }
    }
  };

  return (
    <Flex direction="column" pt={{ base: '65px' }}>
      {/* Hide IPSTHeader on mobile */}
      <Box display={{ base: 'none', md: 'block' }}>
        <IPSTHeader />
      </Box>

      <Card bg={bgCard} px="5px" mt={2}>
        <Flex
          direction={{ base: 'column', md: 'row' }} // Stack elements on mobile
          justify="space-between"
          align="center"
          px="22px"
        >
          <Flex align="center" mb={{ base: 4, md: 0 }}>
            <Icon as={AiOutlineSearch} color="white" w="20px" h="20px" me="8px" />
            <Input
              w={{base: "300px", md: "450px"}}
              placeholder="ค้นหาชื่อชั้นเรียน/รหัสชั้นเรียน..."
              value={searchKey}
              onChange={handleSearchChange}
              border={{}}
              _hover={{}}
              _focus={{}}
              color="white"
            />
          </Flex>
          {<Button leftIcon={<AddIcon />} onClick={handleAddClassClick}>เพิ่มชั้นเรียน</Button>}
        </Flex>
        <HSeparator my="10px" />

        <Flex w="100%" direction={{ base: 'column', md: 'row' }}>
          <Flex w={{ base: '100%', md: role !== "STUDENT" ? "75%" : "100%" }} bgColor="white" overflow={"hidden"} mr={{ base: 0, md: 2 }}>
            <Box w="100%" pt={5} px={10} overflowX="auto">
              <Table variant="simple" minWidth="100%">
                <Thead>
                  <Tr>
                    <Th w={"20px"}>ที่</Th>
                    <Th>ชั้นเรียน</Th>
                    <Th w={"100px"}>รหัสชั้นเรียน</Th>
                    {role !== "STUDENT" && <Th w={"120px"}>email</Th>}
                    {role !== "STUDENT" && <Th w={"180px"}>ระดับชั้น</Th>}
                    <Th>ปี พ.ศ.</Th>
                    <Th w={"90px"}>ภาคเรียน</Th>
                    <Th minW={"220px"}>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {classroomData.map((row, index) => (
                    <Tr key={row.id}>
                      <Td>{index + 1 + currentPage * rowsPerPage}</Td> {/* Calculate the index number */}
                      <Td>{row.subject_name}</Td>
                      <Td>{row.join_code}</Td>
                      {role !== "STUDENT" && <Td>{(row.user_id)?row.user_email:row.strapi_admin_email}</Td>}
                      {role !== "STUDENT" && <Td>{row.class_name_thai}</Td>}
                      <Td>{row.year + 543}</Td>
                      <Td>{row.term}</Td>
                      <Td>
                        <IconButton 
                          size={"md"}
                          color={"red"} 
                          mr={{base: "1", md: "2", sm: "3"}} 
                          icon={<IoTrashBinOutline />}
                          onClick={()=>{
                            handleDelete(row)
                          }} />
                        {role !== "STUDENT" && (
                          <IconButton 
                            size={"md"}
                            color={"brand"} 
                            mr={{base: "1", md: "2", sm: "3"}} 
                            icon={<EditIcon />} 
                            onClick={() => handleEditClassClick(row)} 
                          />
                        )}
                        <IconButton size={"md"} onClick={() => {
                          goToComponent(row);
                        }} color={"green"} icon={<FaEye />} />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Flex mt={2} justify={"space-between"}>
                <Flex>
                  <Text pt={2} mr={1}>แสดงจำนวน</Text>
                  <Select
                    w="100px"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Select>
                  <Text ml={1} pt={2}>แถวต่อหน้า</Text>
                </Flex>
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  forcePage={currentPage}
                />
              </Flex>
            </Box>
          </Flex>
          {role !== "STUDENT" && (
            <Flex w={{ base: '100%', md: '25%' }} bgColor="white" overflow={"hidden"} mt={{ base: 4, md: 0 }}>
              <Stack w="100%" direction="column" spacing="24px">
                <ClassLevelSelectionCard setSelectedGradeLevels={(level)=>{
                  setSelectedGradeLevels(level)
                }} />
                <Box p={4} borderWidth={1} borderRadius="lg">
                  <Text fontWeight="bold" mb={2}>เลือกปีการศึกษา</Text>
                  <CheckboxGroup value={selectedYears}>
                    {getLast10Years().map(year => (
                      <Checkbox
                        mr={4}
                        mb={2}
                        key={year}
                        value={year}
                        isChecked={selectedYears.includes(year)}
                        onChange={() => handleYearChange(year)}
                      >
                        {year + 543}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Box>
              </Stack>
            </Flex>
          )}
        </Flex>
      </Card>

      {/* Modal for adding classroom */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{role === 'STUDENT' ? 'เข้าร่วมชั้นเรียน' : (editMode ? 'แก้ไขชั้นเรียน' : 'เพิ่มชั้นเรียน')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {role === 'STUDENT' ? (
              <FormControl isInvalid={errors.joinCode}>
                <FormLabel>รหัสในการเข้าร่วมชั้นเรียน</FormLabel>
                <Input
                  placeholder="ใส่รหัสที่ได้จากอาจารย์"
                  value={joinCode}
                  onChange={(e) => setJoinCode(e.target.value)}
                />
                {errors.joinCode && <FormErrorMessage>{errors.joinCode}</FormErrorMessage>}
              </FormControl>
            ) : (
              <>
                <FormControl mb={3} isInvalid={errors.className}>
                  <FormLabel>ชื่อชั้นเรียน</FormLabel>
                  <Input
                    placeholder="ใส่ชื่อชั้นเรียน"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                  />
                  {errors.className && <FormErrorMessage>{errors.className}</FormErrorMessage>}
                </FormControl>
                <FormControl mb={3} isInvalid={errors.gradeLevel}>
                  <FormLabel>ระดับชั้น</FormLabel>
                  <Select
                    placeholder="-- เลือกระดับชั้นเรียน --"
                    value={gradeLevel}
                    onChange={(e) => setGradeLevel(e.target.value)}
                  >
                    {classes.map((c,i)=>(
                      <option key={c.id} value={c.id}>{c.name_thai}</option>
                    ))}
                  </Select>
                  {errors.gradeLevel && <FormErrorMessage>{errors.gradeLevel}</FormErrorMessage>}
                </FormControl>
                <FormControl mb={3} isInvalid={errors.year}>
                  <FormLabel>ปีการศึกษา</FormLabel>
                  <Select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    {getLast10Years().map((year) => (
                      <option key={year} value={year}>
                        {year + 543}
                      </option>
                    ))}
                  </Select>
                  {errors.year && <FormErrorMessage>{errors.year}</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={errors.term}>
                  <FormLabel>ภาคการศึกษา</FormLabel>
                  <Select
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                  >
                    <option value="1">ภาคเรียนที่ 1</option>
                    <option value="2">ภาคเรียนที่ 2</option>
                    <option value="3">ภาคเรียนที่ 3</option>
                  </Select>
                  {errors.term && <FormErrorMessage>{errors.term}</FormErrorMessage>}
                </FormControl>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {role === 'STUDENT' ? 'เข้าร่วม' : (editMode ? 'บันทึกการเปลี่ยนแปลง' : 'เพิ่ม')}
            </Button>
            <Button variant="ghost" onClick={onClose}>ยกเลิก</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ClassroomList;
