import { useAuth } from "auth/AuthProvider";
import React, { useEffect, useState } from "react";
import { showInfoDialog } from "util/alertUtil";

const InactivityChecker = () => {
  const [isInactive, setIsInactive] = useState(false);
  const timeoutDuration = 30 * 60 * 1000; // 30 minutes
  const { getUser, role, login, logout, isUserExist } = useAuth();

  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      // Clear the existing timeout and set a new one
      //console.log("------>")
      clearTimeout(timeout);
      setIsInactive(false);

      timeout = setTimeout(() => {
        logout();
        setIsInactive(true); // Set user as inactive after 30 minutes
        handleInactivity(); // Trigger action on inactivity
      }, timeoutDuration);
    };

    // Activity event listeners
    const activityEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    resetTimer(); // Start the initial timer

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timeout);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
  }, []);

  const handleInactivity = () => {
    // Action to take after inactivity (e.g., logout, show alert)
    showInfoDialog("ระบบไม่ถูกใช้งานเกิน 30 นาที", "กรุณา login เพื่อเข้าใช้งานระบบชั้นเรียนใหม่")
    // You can also trigger a logout or another action here
  };

  return (
    <></>
  );
};

export default InactivityChecker;
